import CoordinateType from "@/store/redux/types/CoordinateType";

export const fetchAddressText = (payload: any) => ({
  type: CoordinateType.FETCH_ADDRESS_TEXT,
  payload
});

export const fetchAddressTextSucceed = (payload: any) => ({
  type: CoordinateType.FETCH_ADDRESS_TEXT_SUCCEED,
  payload
});

export const fetchAddressTextFailed = (payload: any) => ({
  type: CoordinateType.FETCH_ADDRESS_TEXT_FAILED,
  payload
});
