import gql from "graphql-tag";

export const updateJobApplyMutations = () => `
    mutation updateJobApply ($input: JobApplyInput!) {
        employerJobApplyUpdate (input: $input) {
            _id
        }
    }
`;
export const UPDATE_STATUS_JOB_APPLY = gql`
    mutation updateJobApply ($input: JobApplyInput!) {
        jobStatusApplyUpdate (input: $input) {
            status
        }
    }
`;