import CoordinateType from "@/store/redux/types/CoordinateType";
import {all, fork, put, takeLatest} from "redux-saga/effects";
import {COORDINATE_TEXT} from "@/graphql/coordinate/query";
import {apolloClient} from "@/utils/apolloClient";
import {fetchAddressTextSucceed} from "@/store/redux/actions/CoordinateAction";

function* fetchAddressTextSaga(action: any) {
  try {
    let r = yield apolloClient.query({query: COORDINATE_TEXT, variables: {text: action.payload.text}});
    yield put(fetchAddressTextSucceed(r.data.coordinateText.edges))
  } catch (e) {
    console.log(e)
  }
}

function* watchFetchCoordinateSaga() {
  yield takeLatest(CoordinateType.FETCH_ADDRESS_TEXT, fetchAddressTextSaga)
}

function* coordinateSaga() {
  yield all([fork(watchFetchCoordinateSaga)]);
}

export default coordinateSaga;
