import UserType from "@/store/redux/types/UserType";
import { UserAction } from "@/store/redux/actions/UserAction";

interface UserState {
  user_loading: boolean;
  user: any;
}

const initState: UserState = {
  user_loading: false,
  user: null
};


export const UserReducer = (state: UserState = initState, action: UserAction) => {
  switch (action.type) {
    case UserType.FETCH_USER:
      return {
        ...state,
        user_loading: true,
        user: null
      }
    case UserType.FETCH_USER_SUCCEED:
      return {
        ...state,
        user_loading: false,
        user: action.payload.user,
      };
    case UserType.FETCH_USER_FAILED:
      return {
        ...state,
        user_loading: false,
        user: null,
      };
    default:
      return state;
  }
};
