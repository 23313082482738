import JobCategoryType from "@/store/redux/types/JobCategoryType";

export const fetchJobCategory = (payload: any) => ({
  type: JobCategoryType.FETCH_JOB_CATEGORY,
  payload
});

export const fetchJobCategorySucceed = (payload: any) => ({
  type: JobCategoryType.FETCH_JOB_CATEGORY_SUCCEED,
  payload
});

export const fetchJobCategoryFailed = () => ({
  type: JobCategoryType.FETCH_JOB_CATEGORY_FAILED,
});
