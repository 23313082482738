import { getListJobType } from "@/graphql/job_type/query";
import { fetchJobTypeFailed, fetchJobTypeSucceed } from "@/store/redux/actions/JobTypeAction";
import JobTypeType from "@/store/redux/types/JobTypeType";
import { all, fork, put, takeLatest } from "redux-saga/effects";
import { axios } from "@/utils/axiosInstance";

function* fetchJobType(action: any) {
  try {
    let actionPayload = action.payload;
    let r = yield axios.post("/graphql", {
      query: getListJobType(actionPayload.page, actionPayload.limit, actionPayload.filter)
    });
    let responsePayload = {
      job_types: r.data.data.jobTypes.edges,
      job_types_length: r.data.data.jobTypes.pageInfo.length,
    }
    yield put(fetchJobTypeSucceed(responsePayload))
  } catch (e) {
    yield put(fetchJobTypeFailed())
  }
}

function* watchFetchJobType() {
  yield takeLatest(JobTypeType.FETCH_JOB_TYPE, fetchJobType)
}

function* jobTypeSaga() {
  yield all([
    fork(watchFetchJobType),
  ])
}

export default jobTypeSaga;
