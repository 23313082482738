
export const getListCity = (page: number, limit: number, filter: string) => `
    query {
        citys(page: ${page}, limit: ${limit}, filter: "${filter}") {
            edges {
                node {
                    _id
                    name
                }
            }
            pageInfo {
                length
            }
        }
    }
`;
