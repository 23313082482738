import { cvWarehouseCreateMutation, cvWarehouseUpdateMutation } from "@/graphql/cv_warehouse/mutation";
import { getCvWarehouseById, getListCvWarehouse } from "@/graphql/cv_warehouse/query";
import { cvEmployerCreateMutation, cvEmployerUpdateMutation } from "@/graphql/cv_employer/mutation";
import { getCvEmployerById, getListCvEmployer } from "@/graphql/cv_employer/query";
import { closeModal } from "@/store/redux/actions/GlobalModalAction";
import { enqueueSnackbar } from "@/store/redux/actions/GlobalToastAction";
import {
    createWarehouseFailed,
    createWarehouseSucceed, deleteWarehouseFailed, deleteWarehouseSucceed,
    fetchListWarehouseFailed, fetchListWarehouseSucceed, fetchWarehouseFailed, fetchWarehouseSucceed,
    updateWarehouseFailed, updateWarehouseSucceed,
    createCv,
    createCvFailed,
    createCvSucceed,
    deleteCv,
    deleteCvFailed,
    deleteCvSucceed,
    fetchCv,
    fetchCvFailed,
    fetchCvSucceed,
    fetchListCv,
    fetchListCvFailed,
    fetchListCvSucceed,
    updateCv,
    updateCvFailed,
    updateCvSucceed,
} from "@/store/redux/actions/WarehouseAction";
import WarehouseType from "@/store/redux/types/WarehouseType";
import { axios } from "@/utils/axiosInstance";
import { all, fork, put, takeLatest } from "redux-saga/effects";


function* createWarehouseSaga(action: any) {
    try {
        let actionPayload = action.payload;
        let r = yield axios.post("/graphql", {
            query: cvWarehouseCreateMutation(),
            variables: actionPayload.variables,
        })
        const toast = {
            message: "Cập nhật thành công",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
            },
        };
        yield put(closeModal());
        yield put(enqueueSnackbar(toast));
        yield put(createWarehouseSucceed());
        yield (window.location.href = `/kho-cv/danh-sach`);
    } catch (e) {
        const toast = {
            message: "Lỗi cập nhât máy chủ",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
            },
        };
        yield put(closeModal());
        yield put(enqueueSnackbar(toast));
        yield put(createWarehouseFailed());
    }
}

function* fetchWarehouseSaga(action: any) {
    try {
        let actionPayload = action.payload;
        let r = yield axios.post("/graphql", {
            query: getCvWarehouseById(actionPayload._id)
        })
        let responsePayload = {
            warehouse: r.data.data.cvWarehouse
        }
        yield put(fetchWarehouseSucceed(responsePayload));
    } catch (e) {
        yield put(fetchWarehouseFailed());
    }
}

function* updateWarehouseSaga(action: any) {
    try {
        let actionPayload = action.payload;
        let r = yield axios.post("/graphql", {
            query: cvWarehouseUpdateMutation(),
            variables: actionPayload.variables,
        })
        const toast = {
            message: "Cập nhật thành công",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
            },
        };
        yield put(closeModal());
        yield put(enqueueSnackbar(toast));
        yield put(updateWarehouseSucceed());
        if (actionPayload.redirect) {
            yield window.location.href = actionPayload.redirect;
        } else {
            yield window.location.reload();
        }

    } catch (e) {
        const toast = {
            message: "Lỗi cập nhât máy chủ",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
            },
        };
        yield put(closeModal());
        yield put(enqueueSnackbar(toast));
        yield put(updateWarehouseFailed());
    }
}

function* deleteWarehouseSaga(action: any) {
    try {
        let actionPayload = action.payload;
        let r = yield axios.post("/graphql", {
            query: cvWarehouseUpdateMutation(),
            variables: actionPayload.variables,
        })
        const toast = {
            message: "Cập nhật thành công",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
            },
        };
        yield put(closeModal());
        yield put(enqueueSnackbar(toast));
        yield put(deleteWarehouseSucceed());
        yield window.location.reload();
    } catch (e) {
        const toast = {
            message: "Lỗi cập nhât máy chủ",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
            },
        };
        yield put(closeModal());
        yield put(enqueueSnackbar(toast));
        yield put(deleteWarehouseFailed());
    }
}

function* fetchListWarehouseSaga(action: any) {
    try {
        let actionPayload = action.payload;
        let r = yield axios.post("/graphql", {
            query: getListCvWarehouse(actionPayload.page, actionPayload.limit, actionPayload.filter)
        });

        let responsePayload = {
            warehouses: r.data.data.cvWarehouses.edges,
            warehouses_length: r.data.data.cvWarehouses.pageInfo.length,
        }

        yield put(fetchListWarehouseSucceed(responsePayload));
    } catch (e) {
        yield put(fetchListWarehouseFailed());
    }
}

function* createCvSaga(action: any) {
    try {
        let actionPayload = action.payload;
        let r = yield axios.post("/graphql", {
            query: cvEmployerCreateMutation(),
            variables: actionPayload.variables,
        })
        const toast = {
            message: "Cập nhật thành công",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
            },
        };
        yield put(closeModal());
        yield put(enqueueSnackbar(toast));
        yield put(createCvSucceed());
        yield (window.location.href = `/kho-cv/danh-sach`);
    } catch (e) {
        const toast = {
            message: "Lỗi cập nhât máy chủ",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
            },
        };
        yield put(closeModal());
        yield put(enqueueSnackbar(toast));
        yield put(createCvFailed());
    }
}

function* fetchCvSaga(action: any) {
    try {
        let actionPayload = action.payload;
        let r = yield axios.post("/graphql", {
            query: getCvEmployerById(actionPayload._id)
        })
        let responsePayload = {
            cv: r.data.data.cvEmployer
        }
        yield put(fetchCvSucceed(responsePayload));
    } catch (e) {
        yield put(fetchCvFailed());
    }
}

function* updateCvSaga(action: any) {
    try {
        let actionPayload = action.payload;
        let r = yield axios.post("/graphql", {
            query: cvEmployerUpdateMutation(),
            variables: actionPayload.variables,
        })
        const toast = {
            message: "Cập nhật thành công",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
            },
        };
        yield put(closeModal());
        yield put(enqueueSnackbar(toast));
        yield put(updateCvSucceed());
        if (actionPayload.redirect) {
            yield window.location.href = actionPayload.redirect;
        } else {
            yield window.location.reload();
        }

    } catch (e) {
        const toast = {
            message: "Lỗi cập nhât máy chủ",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
            },
        };
        yield put(closeModal());
        yield put(enqueueSnackbar(toast));
        yield put(updateCvFailed());
    }
}

function* deleteCvSaga(action: any) {
    try {
        let actionPayload = action.payload;
        let r = yield axios.post("/graphql", {
            query: cvEmployerUpdateMutation(),
            variables: actionPayload.variables,
        })
        const toast = {
            message: "Cập nhật thành công",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
            },
        };
        yield put(closeModal());
        yield put(enqueueSnackbar(toast));
        yield put(deleteCvSucceed());
        yield window.location.reload();
    } catch (e) {
        const toast = {
            message: "Lỗi cập nhât máy chủ",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
            },
        };
        yield put(closeModal());
        yield put(enqueueSnackbar(toast));
        yield put(deleteCvFailed());
    }
}

function* fetchListCvSaga(action: any) {
    try {
        let actionPayload = action.payload;
        let r = yield axios.post("/graphql", {
            query: getListCvEmployer(actionPayload.page, actionPayload.limit, actionPayload.filter)
        });

        let responsePayload = {
            cvs: r.data.data.cvEmployers.edges,
            cvs_length: r.data.data.cvEmployers.pageInfo.length,
        }

        yield put(fetchListCvSucceed(responsePayload));
    } catch (e) {
        yield put(fetchListCvFailed());
    }
}

function* watchFetchWarehouseSaga() {
    yield takeLatest(WarehouseType.CREATE_WAREHOUSE, createWarehouseSaga);
    yield takeLatest(WarehouseType.DELETE_WAREHOUSE, deleteWarehouseSaga);
    yield takeLatest(WarehouseType.FETCH_WAREHOUSE, fetchWarehouseSaga);
    yield takeLatest(WarehouseType.UPDATE_WAREHOUSE, updateWarehouseSaga);
    yield takeLatest(WarehouseType.FETCH_LIST_WAREHOUSE, fetchListWarehouseSaga);

    yield takeLatest(WarehouseType.CREATE_CV, createCvSaga);
    yield takeLatest(WarehouseType.DELETE_CV, deleteCvSaga);
    yield takeLatest(WarehouseType.FETCH_CV, fetchCvSaga);
    yield takeLatest(WarehouseType.UPDATE_CV, updateCvSaga);
    yield takeLatest(WarehouseType.FETCH_LIST_CV, fetchListCvSaga);
}

function* warehouseSaga() {
    yield all([fork(watchFetchWarehouseSaga)]);
}

export default warehouseSaga;
