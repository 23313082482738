import LoadingAppType, { ILoadingAppState } from "@/store/redux/types/LoadingAppType";

const defaultState: ILoadingAppState = {
  loading: true,
};

export const LoadingAppReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case LoadingAppType.LOADING_APP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
