import ListCandidateType from "@/store/redux/types/ListCandidateType";
import {takeLatest, put, fork, all, delay} from "redux-saga/effects";
import {apolloClient} from "@/utils/apolloClient";
import {fetchListCandidateFailed, fetchListCandidateSucceed} from "@/store/redux/actions/ListCandidateAction";
import {PAGINATE_CANDIDATE} from "@/graphql/candidate/query";

function* fetchListCandidate(action: any) {
  try {
    yield delay(300);
    let r = yield apolloClient.query({query: PAGINATE_CANDIDATE, variables: action.payload.variables});
    yield put(fetchListCandidateSucceed({
      candidate: r.data.candidates.edges,
      length: r.data.candidates.pageInfo.length,
    }))
  } catch (e) {
    yield put(fetchListCandidateFailed())
  }
}

function* watchFetchListCandidate() {
  yield takeLatest(ListCandidateType.FETCH_LIST_CANDIDATE, fetchListCandidate)
}

function* listCandidateSaga() {
  yield all([
    fork(watchFetchListCandidate),
  ])
}

export default listCandidateSaga;
