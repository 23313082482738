enum CoordinateType {
  FETCH_ADDRESS_TEXT = "@@/Coordinate/FETCH_ADDRESS_TEXT",
  FETCH_ADDRESS_TEXT_SUCCEED = "@@/Coordinate/FETCH_ADDRESS_TEXT_SUCCEED",
  FETCH_ADDRESS_TEXT_FAILED = "@@/Coordinate/FETCH_ADDRESS_TEXT_FAILED",

  FETCH_CITY = "@@/Coordinate/FETCH_CITY",
  FETCH_CITY_SUCCEED = "@@/Coordinate/FETCH_CITY_SUCCEED",
  FETCH_CITY_FAILED = "@@/Coordinate/FETCH_CITY_FAILED",

  FETCH_DISTRICT = "@@/Coordinate/FETCH_DISTRICT",
  FETCH_DISTRICT_SUCCEED = "@@/Coordinate/FETCH_DISTRICT_SUCCEED",
  FETCH_DISTRICT_FAILED = "@@/Coordinate/FETCH_DISTRICT_FAILED",

  FETCH_WARD = "@@/Coordinate/FETCH_WARD",
  FETCH_WARD_SUCCEED = "@@/Coordinate/FETCH_WARD_SUCCEED",
  FETCH_WARD_FAILED = "@@/Coordinate/FETCH_WARD_FAILED",
}

interface ICoordinateState {
  address: any[],
}

export default CoordinateType;
export {ICoordinateState}
