import { fetchListCompanyFailed } from "@/store/redux/actions/ListCompanyAction";
import ListCompanyType from "@/store/redux/types/ListCompanyType";
import { all, delay, fork, put, takeLatest } from "redux-saga/effects";

function* fetchListCompanySaga(action: any) {
  try {
    yield delay(300);
    // let r = yield apolloClient.query({query: PAGINATE_COMPANY, variables: action.payload.variables});
    // yield put(fetchListCompanySucceed({company: r.data.companys.edges, length: r.data.companys.pageInfo.length}))
  } catch (e) {
    yield put(fetchListCompanyFailed())
  }
}

function* watchFetchListCompanySaga() {
  yield takeLatest(ListCompanyType.FETCH_LIST_COMPANY, fetchListCompanySaga)
}

function* listCompanySaga() {
  yield all([fork(watchFetchListCompanySaga)]);
}

export default listCompanySaga;
