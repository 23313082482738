import ApplyListType, { ApplyListState } from "@/store/redux/types/ApplyListType";
const defaultState: ApplyListState = {
  job_apply: [],
  hasNextPage: false,
  count: 0,
  page: 1,
  loading: false,
};
export const ApplyListReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case ApplyListType.FETCH_JOB_APPLY:
      return {
        ...state,
        loading: true,
      };
    case ApplyListType.FETCH_JOB_APPLY_SUCCESS:
      return {
        ...state,
        loading: false,
        job_apply: action.payload.edges,
        count: action.payload.pageInfo.length,
        hasNextPage: action.payload.pageInfo.hasNextPage,
      };
    case ApplyListType.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
};
