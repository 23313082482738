import palette from "@/theme/palette";

export default {
  root: {

  },
  input: {
    '&::placeholder': {
      opacity: 1,
      color: palette.text.secondary
    },
  },
};
