import default_user_img from "@/assets/images/default-user.png";
import Navigation from "@/components/Navigation/Navigation";
import { graphqlFilter } from "@/helpers/string";
import { fetchCompany } from "@/store/redux/actions/CompanyAction";
import {closeModal, openModal} from "@/store/redux/actions/GlobalModalAction";
import { AppState } from "@/store/redux/reducers/rootReducer";
import { axios } from "@/utils/axiosInstance";
import useRouter from "@/utils/useRouter";
import { Avatar, Button, Divider, Drawer, Hidden, Paper, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BusinessIcon from "@material-ui/icons/Business";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import HomeWorkIcon from "@material-ui/icons/HomeWorkOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import PermMediaOutlinedIcon from "@material-ui/icons/PermMediaOutlined";
import PostAddIcon from "@material-ui/icons/PostAdd";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import classNames from "classnames";
import React, { ComponentType, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflowY: "auto",
    position: "fixed",
    top: 65,
    left: 0,
    height: "calc(100% - 65px)",
    boxShadow: "none",
  },
  rootMobile: {
    height: "100%",
    overflowY: "auto",
  },
  content: {
    padding: theme.spacing(2),
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
    fontWeight: 900,
    wordBreak: "break-all",
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
  creditTitle: {
    marginBottom: theme.spacing(1),
  },
  creditValue: {
    fontWeight: 600,
    wordBreak: "break-all",
  },
  creditBuy: {
    cursor: "pointer",
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  info: {
    marginTop: theme.spacing(2)
  }
}));

interface NavBarProps {
  className?: string;
  onMobileClose: () => void;
  openMobile: boolean;
}

const NavBar: ComponentType<NavBarProps> = (props: NavBarProps) => {
  const { openMobile, onMobileClose, className, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.user);
  const credit = useSelector((state: AppState) => state.credit);

  const company = useSelector((state: AppState) => state.company);
  let userInfo = user.user;

  const router = useRouter();
  const history = useHistory();
  const [slug, setSlug] = useState<string>("");

  const navMain = [
    {
      title: "Bảng điều khiển",
      pages: [
        {
          title: "Tổng quan",
          href: "/tong-quan",
          icon: HomeIcon,
        },
        // {
        //   title: "Thông báo",
        //   href: "/notification",
        //   icon: NotificationsIcon,
        //   label: notification.unread_length ? (
        //     <>
        //       <Label shape={"rounded"} variant={"contained"} color={"#e53935"}>
        //         {notification.unread_length}
        //       </Label>
        //     </>
        //   ) : (
        //       ""
        //     ),
        // },
        // {
        //   title: "Tìm ứng viên",
        //   href: "/find-candidate",
        //   icon: SearchOutlinedIcon,
        // },
      ],
    },
    {
      title: "Quản lý",
      pages: [
        {
          title: "Tin tuyển dụng",
          href: "/viec-lam",
          icon: PostAddIcon,
          children: [
            {
              title: "Tạo mới",
              href: `/viec-lam/tao-moi`,
            },
            {
              title: "Đang hoạt động",
              href: "/viec-lam/hoat-dong",
            },
            {
              title: "Đã hết hạn",
              href: "/viec-lam/het-han",
            },
            {
              title: "Đã xoá",
              href: "/viec-lam/thung-rac",
            },
          ],
        },
        // {
        //   title: "CV",
        //   href: "/cv",
        //   icon: RecentActorsOutlinedIcon,
        //   children: [
        //     {
        //       title: "Tải lên CV mới",
        //       href: "/cv/upload",
        //     },
        //     {
        //       title: "Danh sách",
        //       href: "/cv/list",
        //     },
        //   ],
        // },
        {
          title: "Ứng tuyển",
          href: "/ung-tuyen",
          icon: PermContactCalendarOutlinedIcon,
          children: [
            {
              title: "Đang chờ",
              href: "/ung-tuyen/dang-cho",
            },
            {
              title: "Đã duyệt",
              href: "/ung-tuyen/da-duyet",
            },
            {
              title: "Từ chối",
              href: "/ung-tuyen/tu-choi",
            },
          ],
        },
        {
          title: "Tìm ứng viên",
          href: "/tim-ung-vien",
          children: [
            {
              title: "Kho ứng viên",
              href: "/tim-ung-vien/kho",
            },
            {
              title: "Ứng viên đã mở",
              href: "/tim-ung-vien/da-mo",
            },
          ],
          icon: PictureAsPdfOutlinedIcon,
        },
        // {
        //   title: "Kho CV",
        //   href: "/kho-cv",
        //   children: [
        //     {
        //       title: "Tạo mới",
        //       href: "/kho-cv/tao-moi",
        //     },
        //     {
        //       title: "Danh sách",
        //       href: "/kho-cv/danh-sach",
        //     },
        //   ],
        //   icon: PictureAsPdfOutlinedIcon,
        // },
        {
          title: "Công ty",
          href: "/cong-ty",
          children: [
            {
              title: "Tạo mới",
              href: "/cong-ty/tao-moi",
            },
            {
              title: "Danh sách",
              href: "/cong-ty/danh-sach",
            },
          ],
          icon: BusinessIcon,
        },
      ],
    },
  ];

  const navCompany = [
    {
      title: "Bảng điều khiển",
      pages: [
        {
          title: "Trở về",
          href: `/cong-ty/danh-sach`,
          icon: ArrowBackIcon,
        },
        {
          title: "Tổng quan",
          href: `/cong-ty/${slug}`,
          icon: HomeIcon,
        },
        {
          title: "Văn phòng",
          href: `/cong-ty/${slug}/van-phong`,
          icon: HomeWorkIcon,
        },
        {
          title: "Con người",
          href: `/cong-ty/${slug}/con-nguoi`,
          icon: PeopleIcon,
        },
        {
          title: "Hình ảnh",
          href: `/cong-ty/${slug}/hinh-anh`,
          icon: PermMediaOutlinedIcon,
        },
        {
          title: "Phúc lợi",
          href: `/cong-ty/${slug}/phuc-loi`,
          icon: FavoriteBorderOutlinedIcon,
        },
        {
          title: "Câu chuyện",
          href: `/cong-ty/${slug}/cau-chuyen`,
          icon: InfoIcon,
        },
        {
          title: "Cài đặt",
          href: `/cong-ty/${slug}/cai-dat`,
          icon: SettingsOutlinedIcon,
        },
      ],
    },
  ];

  // TODO fixed slug
  const isSingleCompany =
    router.location.pathname.includes("cong-ty/") &&
    !router.location.pathname.includes("cong-ty/tao-moi") &&
    !router.location.pathname.includes("cong-ty/danh-sach");

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
  }, [router.location.pathname]);

  useEffect(() => {
    if (isSingleCompany) {
      let r = router.location.pathname.split("/");
      if (r[2]) {
        setSlug(r[2]);
        let payload = {
          _id: r[2],
        };
        dispatch(fetchCompany(payload));
      }
    }
  }, [isSingleCompany]);

  useEffect(() => {
    let notificationPayload = {
      variables: {
        page: 1,
        limit: 1,
        filter: graphqlFilter({ target_ref: userInfo._id, read: false }),
      },
    };
    // dispatch(fetchUnreadNotificationLength(notificationPayload));
  }, []);

  const handleClickLogout = () => {
    let payload = {
      title: "Bạn chắc chắn đăng xuất?",
      content: (
        <>
          Chọn <strong>OK</strong> để đăng xuất
        </>
      ),
      action: () => handleLogout(),
    };
    dispatch(openModal(payload));
  }

  const handleLogout = () => {
    axios.post("/auth/employer/logout").then(r => {
      window.location.href = '/';
    })
  }

  const handleClickBuy = () => {
    let payload = {
      title: "Liên hệ với chúng tôi",
      content:
        <>
        <p>Để mua Xu, bạn vui lòng liên hệ với chúng tôi để nhận ưu đãi.</p>
        <p className={classes.info}><strong>Công ty Cổ phần Công nghệ Kết Nối Việc</strong></p>
          <p className={classes.info}><strong>Địa chỉ:</strong> 348 Tố Hữu, Phường La Khê, Quận Hà
            Đông, Hà Nội</p>
          <p className={classes.info}><strong>Số điện thoại:</strong> <a href="tel:0976.658.066">0976.658.066
            (Mrs. Quỳnh)</a></p>
          <p className={classes.info}><strong>Email:</strong> quynhnguyen@ketnoiviec.net</p>
      </>,
      action: () => {
        dispatch(closeModal())
        history.push('/bang-gia');
      },
      actionTitle: "Xem bảng giá"
    };
    dispatch(openModal(payload))
  }

  const navbarContent = (
    <div className={classes.content}>
      {isSingleCompany ? (
        <>
          {
            company.company ? (
              <div>
                <div className={classes.profile}>
                  <Avatar alt="Person" className={classes.avatar} src={company.company.logo || default_user_img} />
                  <Typography className={classes.name}>{company.company.name}</Typography>
                </div>
                <Divider className={classes.divider} />
                <nav className={classes.navigation}>
                  {navCompany.map((list, index) => (
                    <Navigation component="div" pages={list.pages} title={list.title} key={index} />
                  ))}
                </nav>
              </div>
            ) : (
                <></>
              )
          }
        </>
      ) : (
          <div>
            <div className={classes.profile}>
              <Avatar alt="Person" className={classes.avatar} src={userInfo.avatar} />
              <Typography className={classes.name}>{`${userInfo.first_name} ${userInfo.last_name}`.trim()}</Typography>
              <Typography style={{ wordBreak: "break-all", textAlign: "center" }}>{userInfo.email}</Typography>
              <Button variant={"outlined"} color="primary" size="small" onClick={() => handleClickLogout()}>Đăng xuất</Button>
            </div>
            <Divider className={classes.divider} />
            <Navigation component="div" pages={[]} title={""} className={classes.creditTitle}/>
            <Typography className={classes.creditValue}>Xu còn lại: {credit.credit}</Typography>
            <Typography className={classes.creditBuy} onClick={() => history.push('/lich-su-don-hang')}>Lịch sử đơn hàng</Typography>
            <Typography className={classes.creditBuy} onClick={() => handleClickBuy()}>Mua Xu</Typography>
            <Divider className={classes.divider} />
            <nav className={classes.navigation}>
              {navMain.map((list, index) => (
                <Navigation component="div" pages={list.pages} title={list.title} key={index} />
              ))}
            </nav>
          </div>
        )}
    </div>
  );

  return (
    <div style={{ maxWidth: 256 }}>
      <Fragment>
        <Hidden mdUp>
          <Drawer anchor="right" onClose={onMobileClose} open={openMobile} variant="temporary">
            <div {...rest} className={classNames(classes.rootMobile, className)}>
              {navbarContent}
            </div>
          </Drawer>
        </Hidden>

        <Hidden smDown>
          <div style={{ width: 256 }}>
            <Paper {...rest} className={classNames(classes.root, className)} elevation={1} square>
              {navbarContent}
            </Paper>
          </div>
        </Hidden>
      </Fragment>
    </div>
  );
};

export default NavBar;
