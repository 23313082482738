import UserType from "@/store/redux/types/UserType";
import { takeLatest, put, all, fork } from "redux-saga/effects";
import { axios } from "@/utils/axiosInstance";
import { loadingAppSuccess } from "@/store/redux/actions/LoadingAppAction";
import { fetchUserFailed, fetchUserSucceed } from "@/store/redux/actions/UserAction";

function* fetchUser() {

  try {
    const response = yield axios({
      url: `${process.env.REACT_APP_API_URL}/auth/employer/login`,
      method: "post",
    });
    if (response.data.employer) {
      let payload = {
        user: response.data.employer
      }
      yield put(fetchUserSucceed(payload));
    } else {
      yield put(fetchUserFailed());
    }
  } catch (e) {
    yield put(fetchUserFailed());
  }
}

function* watchFetchUser() {
  yield takeLatest(UserType.FETCH_USER, fetchUser);
}

function* UserSaga() {
  yield all([fork(watchFetchUser)]);
}

export default UserSaga;
