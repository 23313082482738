enum ApplyListType {
    FETCH_JOB_APPLY = "@@/ApplyList/FETCH_JOB_APPLY",
    FETCH_JOB_APPLY_SUCCESS = "@@/ApplyList/FETCH_JOB_APPLY_SUCCESS",
    FETCH_JOB_APPLY_FAILURE = "@@/ApplyList/FETCH_JOB_APPLY_FAILURE",

    SET_QUICK_VIEW = "@@/ApplyList/SET_QUICK_VIEW",
    SET_CONFIRM = "@@/ApplyList/SET_CONFIRM",
    SET_PAGE = "@@/ApplyList/SET_PAGE",

}

interface ApplyListState {
  job_apply: any[];
  count: number;
  page: number;
  hasNextPage: boolean,
  loading: boolean;
}

export default ApplyListType;
export { ApplyListState };
