import gql from "graphql-tag";

export const getListJobPost = (page: number, limit: number, filter: string) => `
    query {
        jobPosts(page: ${page}, limit: ${limit}, filter: "${filter}") {
            edges {
                node {
                    _id
                    title
                    slug
                    job_level {
                        _id
                        title
                    }
                    job_type {
                        _id
                        title
                    }
                    image
                    job_category {
                        _id
                        title
                    }
                    number
                    description
                    requirement
                    salary {
                        min
                        max
                        show
                    }
                    company {
                        name
                    }
                    status
                    view_count
                    end_date
                    created_at
                    updated_at
                }
            }
            pageInfo {
                length
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

export const JOB_POST = gql`
    query jobPost($_id: String!) {
        jobPost(_id: $_id) {
            _id
            title
            job_level {
                _id
                vi_title
            }
            job_category {
                _id
                vi_title
            }
            description
            requirement
            job_location {
                _id
                vi_title
            }
            salary {
                min
                max
                show
            }
            company {
                name
                ref {
                    _id
                }
            }
            view_count
            created_at
            updated_at
        }
    }
`;

export const getSinglePostById = (_id: string) => `
    query {
        jobPost(_id: "${_id}") {
            _id
            title
            job_type {
                _id
                title
            }
            contact {
                name
                email
                phone
            }
            job_level {
                _id
                title
            }
            job_category {
                _id
                title
            }
            number
            description
            requirement
            address {
                city {
                    _id
                    name
                }
                district {
                    _id
                    name
                }
                ward {
                    _id
                    name
                }
                specific
                text
                lat
                lng
            }
            salary {
                min
                max
                show
            }
            company {
                ref {
                    _id
                }
                name
            }
            image
            photos
            video
            benefit {
                benefit_id {
                    _id
                    title
                }
                content
            }
            end_date
            view_count
            created_at
            updated_at
        }
    }
`;

export const SINGLE_JOB_POST_BY_SLUG = gql`
    query jobPost($slug: String!) {
        jobPost(slug: $slug) {
            _id
            title
            job_type {
                _id
                title
            }
            job_level {
                _id
                title
            }
            job_category {
                _id
                title
            }
            description
            requirement
            address {
                city
                district
                ward
                text
                lat
                lng
            }
            salary {
                min
                max
                show
            }
            company {
                ref
                name
            }
            view_count
            created_at
            updated_at
        }
    }
`;

export const JOB_POST_COUNT = gql`
    query jobPosts($page: Int!, $limit: Int!, $filter: String!) {
        jobPosts(page: $page, filter: $filter, limit: $limit) {
            pageInfo {
                length
            }
        }
    }
`;
