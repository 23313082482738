import gql from "graphql-tag";

export const getListJobApply = (page: number, limit: number, filter: string) => `
  query {
    employerJobApplys(page: ${page}, limit: ${limit}, filter: "${filter}") {
      edges {
        node {
          _id
          job_post {
            title
            slug
            number
            description
            requirement
            image
            salary {
              min
              max
              show
            }
            video
            end_date
            view_count
            status
            seo_title
            seo_description
            created_at
            updated_at
          }
          user {
            _id
            info {
              job_open
              avatar
              name
              birthday
              gender
              date
              phone
              website
              intro
            }
            email
            psid
            first_name
            last_name
            birth_day
            avatar
            gender
            login_type
            spam
          }
          status
          email
          file
          description
          created_at
          updated_at
        }
      }
      pageInfo {
        length
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const getSingleJobApplyById = (_id: string) => `
  query {
    employerJobApply(_id: "${_id}") {
      _id
      job_post {
        title
        slug
        number
        description
        requirement
        image
        salary {
          min
          max
          show
        }
        company {
          name
          ref {
            name
            business_code
            slogan
            logo
            cover
            website
            email
            phone
            facebook
            youtube
            description
            company_type
            verify_status
            premium_status
            slug
            follow
            seo_title
            seo_description
          }
        }
        video
        end_date
        view_count
        status
        seo_title
        seo_description
        created_at
        updated_at
      }
      user {
        _id
        info {
          job_open
          avatar
          name
          birthday
          gender
          date
          phone
          website
          intro
          address {
            lat
            lng
            text
          }
        }
        email
        psid
        first_name
        last_name
        birth_day
        avatar
        gender
        login_type
        spam
      }
      status
      email
      file
      description
      created_at
      updated_at
    }
  }
`;
