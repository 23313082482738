import ListCompanyType, { IListCompanyState } from "@/store/redux/types/ListCompanyType";

const defaultState: IListCompanyState = {
  company: [],
  loading: false
};

export const ListCompanyReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case ListCompanyType.FETCH_LIST_COMPANY:
      return {
        ...state,
        loading: true,
      };
    case ListCompanyType.FETCH_LIST_COMPANY_SUCCEED:
      return {
        ...state,
        company: action.payload.company,
        length: action.payload.length,
        loading: false
      };
    case ListCompanyType.FETCH_LIST_COMPANY_FAILED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
