export const cvEmployerCreateMutation = () => `
  mutation cvEmployerCreate($input: CvEmployerInput!) {
    cvEmployerCreate(input: $input) {
      _id
    }
  }
`;
export const cvEmployerUpdateMutation = () => `
    mutation cvEmployerUpdate($input: CvEmployerInput!) {
      cvEmployerUpdate(input: $input) {
        _id
      }
    }
`;