import ChatBar from "@/layouts/components/ChatBar/ChatBar";
import NavBar from "@/layouts/components/NavBar/NavBar";
import TopBar from "@/layouts/components/TopBar/TopBar";
import { AppRoute, AppRouteList } from "@/routes/Main";
import { fetchUser } from "@/store/redux/actions/UserAction";
import { AppState } from "@/store/redux/reducers/rootReducer";
import LoginPage from "@/views/LoginPage";
import { createStyles, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { ComponentType, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {fetchCredit} from "@/store/redux/actions/CreditAction";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
    topBar: {
      zIndex: 2,
      position: "relative",
    },
    container: {
      display: "flex",
      flex: "1 1 auto",
      overflow: "hidden",
    },
    navBar: {
      zIndex: 3,
      width: 256,
      minWidth: 256,
      flex: "0 0 auto",
      backgroundColor: "white",
    },
    content: {
      // overflowY: "auto",
      // overflowX: "hidden",
      flex: "1 1 auto",
      marginTop: 65,
    },
  })
);

interface MainLayoutProps {
  routes: AppRouteList;
}

const MainLayout: ComponentType<MainLayoutProps> = (props: MainLayoutProps) => {
  const { routes } = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.user);
  let userLoading = user.user_loading;
  let userInfo = user.user;

  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchCredit());
  }, [dispatch]);

  return (
    <Router>
      <div className={classes.root}>
        <TopBar className={classes.topBar} onOpenNavBarMobile={handleNavBarMobileOpen} />
        <div className={classes.container}>
          {
            !userLoading ? (
              <>
                {
                  userInfo ? (
                    <>
                      <NavBar className={classes.navBar} onMobileClose={handleNavBarMobileClose} openMobile={openNavBarMobile} />
                      <main id="main" className={classes.content}>
                        <Suspense fallback={<LinearProgress />}>
                          <Switch>
                            {routes.map((route: AppRoute, index: number) => (
                              <Route key={index} path={route.path} exact={route.exact} children={<route.component />} />
                            ))}
                          </Switch>
                        </Suspense>
                      </main>
                    </>
                  ) : (
                      <main id="main" className={classes.content}>
                        <LoginPage />
                      </main>
                    )
                }
              </>
            ) : null
          }
        </div>
        <ChatBar />
      </div>
    </Router>
  );
};

export default MainLayout;
