import "@/assets/scss/main.scss";
import GlobalModal from "@/components/GlobalModal/GlobalModal";
import GlobalToast from "@/components/GlobalToast/GlobalToast";
import i18n from "@/i18n";
import routes from "@/routes/Main";
import theme from "@/theme";
import palette from "@/theme/palette";
import DateFnsUtils from "@date-io/date-fns";
import { MuiThemeProvider } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { SnackbarProvider } from "notistack";
import React, { ComponentType } from "react";
import ReactGA from "react-ga";
import { I18nextProvider } from "react-i18next";
import MainLayout from "./layouts/MainLayout";

interface AppProps {

}

const initializeReactGA = () => {
  ReactGA.initialize("UA-174979107-1");
  ReactGA.pageview(`/${window.location.pathname}${window.location.search}`);
};

const useStyles = makeStyles({
  fixed: {
    position: "fixed",
    bottom: 40,
    left: 35,
    border: `2px solid ${palette.primary.main}`,
    backgroundColor: palette.primary.main,
    padding: 8,
  },
  icon: {
    fontSize: 35,
    color: "#fff",
  },
  tooltip: {
    backgroundColor: "#fff",
    color: "#666",
    fontSize: 13,
    width: 230,
    padding: "0.5em 0.8em 0.6em",
    borderRadius: 10,
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
  arrow: {
    color: "white",
  },
  imgTooltip: {
    maxWidth: "100%",
    height: "auto",
  },
  contentToast: {
    fontSize: 16,
    lineHeight: 1.2,
  },
});


const App: ComponentType<AppProps> = (props: AppProps) => {

  const classes = useStyles();

  initializeReactGA();

  return (
    <I18nextProvider i18n={i18n}>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <GlobalModal />
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={2000}
            classes={{
              variantSuccess: classes.contentToast,
              variantError: classes.contentToast,
              variantInfo: classes.contentToast,
              variantWarning: classes.contentToast,
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <GlobalToast />
          </SnackbarProvider>
          <MainLayout routes={routes} />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </I18nextProvider>
  );
};

export default App;
