import gql from "graphql-tag";

export const getListJobCategory = (page: number, limit: number, filter: string) => `
  query {
    jobCategorys(page: ${page}, limit: ${limit}, filter: "${filter}") {
      edges {
        node {
          _id
          title
        }
      }
      pageInfo {
        length
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const CATEGORYS_COUNT = gql`
  query categorys($page: Int!, $limit: Int!, $filter: String!) {
    jobCategorys(page: $page, filter: $filter, limit: $limit) {
      pageInfo {
        length
      }
    }
  }
`;
