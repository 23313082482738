import { createCompanyMutation, updateCompanyMutation } from "@/graphql/company/mutation";
import { getListCompany, getSingleCompanyById } from "@/graphql/company/query";
import {
  createCompanyFailed,
  createCompanySucceed,
  fetchCompanySucceed,
  updateCompanySucceed
} from "@/store/redux/actions/CompanyAction";
import { closeModal } from "@/store/redux/actions/GlobalModalAction";
import { enqueueSnackbar } from "@/store/redux/actions/GlobalToastAction";
import CompanyType from "@/store/redux/types/CompanyType";
import { axios } from "@/utils/axiosInstance";
import { all, fork, put, takeLatest } from "redux-saga/effects";
import { fetchListCompanyFailed, fetchListCompanySucceed } from "../../actions/CompanyAction";
import { fetchCompanyFailed, updateCompanyFailed } from "../../actions/CompanyAction/index";


function* createCompanySaga(action: any) {
  try {
    let actionPayload = action.payload;
    let r = yield axios.post("/graphql", {
      query: createCompanyMutation(),
      variables: actionPayload.variables,
    })
    const toast = {
      message: "Cập nhật thành công",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(createCompanySucceed());
    yield window.location.href = `/cong-ty/${r.data.data.companyCreate._id}`;
  } catch (e) {
    const toast = {
      message: "Lỗi cập nhât máy chủ",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(createCompanyFailed());
  }
}

function* fetchCompanySaga(action: any) {
  try {
    let actionPayload = action.payload;
    let r = yield axios.post("/graphql", {
      query: getSingleCompanyById(actionPayload._id)
    })
    let responsePayload = {
      company: r.data.data.company
    }
    yield put(fetchCompanySucceed(responsePayload));
  } catch (e) {
    yield put(fetchCompanyFailed());
  }
}


function* updateCompanySaga(action: any) {
  try {
    let actionPayload = action.payload;
    let r = yield axios.post("/graphql", {
      query: updateCompanyMutation(),
      variables: actionPayload.variables,
    })
    const toast = {
      message: "Cập nhật thành công",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(updateCompanySucceed());
    yield window.location.reload();
  } catch (e) {
    const toast = {
      message: "Lỗi cập nhât máy chủ",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(updateCompanyFailed());
  }
}

function* fetchListCompanySaga(action: any) {
  try {
    let actionPayload = action.payload;
    let r = yield axios.post("/graphql", {
      query: getListCompany(actionPayload.page, actionPayload.limit, actionPayload.filter)
    });
    let responsePayload = {
      companys: r.data.data.companys.edges,
      companys_length: r.data.data.companys.pageInfo.length,
    }
    yield put(fetchListCompanySucceed(responsePayload));
  } catch (e) {
    yield put(fetchListCompanyFailed());
  }
}


function* watchFetchCompanySaga() {
  yield takeLatest([CompanyType.FETCH_LIST_COMPANY], fetchListCompanySaga);
  yield takeLatest([CompanyType.CREATE_COMPANY], createCompanySaga);
  yield takeLatest([CompanyType.FETCH_COMPANY], fetchCompanySaga);
  yield takeLatest([CompanyType.UPDATE_COMPANY], updateCompanySaga);
}

function* CompanySaga() {
  yield all([fork(watchFetchCompanySaga)]);
}

export default CompanySaga;
