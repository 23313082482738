import gql from "graphql-tag";

export const PAGINATE_NOTIFICATION = gql`
  query paginateNotification($page: Int!, $limit: Int!, $filter: String!) {
    notifications(page: $page, filter: $filter, limit: $limit) {
        pageInfo {
            length
            hasNextPage
            hasPreviousPage
        }
      edges {
        node {
          _id
          type
          subject
          target {
            object_type
            ref
          }
          message
          href
          read
          created_at
          updated_at
        }
      }
    }
  }
`;

export const NOTIFICATION_LENGTH = gql`
    query notificationLength($page: Int!, $limit: Int!, $filter: String!) {
        notifications(page: $page, filter: $filter, limit: $limit) {
            pageInfo {
                length
            }
        }
    }
`;
