import gql from "graphql-tag";

export const COORDINATE_TEXT = gql`
  query coordinateText($text: String!) {
    coordinateText(text: $text) {
      edges {
        node {
          _id
          latitude
          longitude
          text
          city
          district
          ward
          street
          house_number
        }
      }
    }
  }
`;
