import JobLevelType from "@/store/redux/types/JobLevelType";
import { takeLatest, put, fork, all, delay } from "redux-saga/effects";
import { apolloClient } from "@/utils/apolloClient";
import { fetchJobLevelFailed, fetchJobLevelSucceed } from "@/store/redux/actions/JobLevelAction";
import { getListJobLevel } from "@/graphql/job_level/query";
import { axios } from "@/utils/axiosInstance";

function* fetchJobLevel(action: any) {
  try {
    let actionPayload = action.payload;
    let r = yield axios.post("/graphql", {
      query: getListJobLevel(actionPayload.page, actionPayload.limit, actionPayload.filter)
    });
    let responsePayload = {
      job_levels: r.data.data.jobLevels.edges,
      job_levels_length: r.data.data.jobLevels.pageInfo.length,
    }
    yield put(fetchJobLevelSucceed(responsePayload))
  } catch (e) {
    yield put(fetchJobLevelFailed())
  }
}

function* watchFetchJobLevel() {
  yield takeLatest(JobLevelType.FETCH_JOB_LEVEL, fetchJobLevel)
}

function* jobLevelSaga() {
  yield all([
    fork(watchFetchJobLevel),
  ])
}

export default jobLevelSaga;
