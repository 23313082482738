enum JobTypeType {
  FETCH_JOB_TYPE = "@@/JobType/FETCH_JOB_TYPE",
  FETCH_JOB_TYPE_SUCCEED = "@@/JobType/FETCH_JOB_TYPE_SUCCEED",
  FETCH_JOB_TYPE_FAILED = "@@/JobType/FETCH_JOB_TYPE_FAILED",
}

interface IJobTypeState {
  job_types: any[],
  job_types_loading: boolean,
  job_types_length: number,
}

export default JobTypeType;
export { IJobTypeState };
