enum CreditType {
  FETCH_CREDIT = "@@/CreditType/FETCH_CREDIT",
  FETCH_CREDIT_SUCCEED = "@@/CreditType/FETCH_CREDIT_SUCCEED",
  FETCH_CREDIT_FAILED = "@@/CreditType/FETCH_CREDIT_FAILED",
}

interface ICreditState {
  credit: number;
  credit_loading: boolean;
}
export default CreditType;
export {ICreditState}
