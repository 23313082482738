import React from 'react';
import Typography from "@material-ui/core/Typography";
import {compose} from "recompose";
import {createStyles, Theme} from "@material-ui/core";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";

interface PageProps {
  children?: any,
  title: string,
  className?: string,
  heading?: string;
  classes: any;
}

const styles = (theme: Theme) => createStyles({
  pageRoot: {
    maxWidth: "1600px",
    margin: "0 auto"
  }
});

const Page: React.FunctionComponent<PageProps> = (props: PageProps) => {
  const {classes, title, className, children, heading, ...rest} = props;
  return (
    <div className={classNames(classes.pageRoot, className)}>
      <div>
        <title>{title}</title>
      </div>
      {
        heading ? (
          <Typography
            variant="h4"
          >
            {heading}
          </Typography>
        ) : null
      }
      {children}
    </div>
  );
};

export default compose<PageProps, any>(
  withStyles(styles)
)(Page);
