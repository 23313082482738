import { getListCity } from "@/graphql/city/query";
import { getListDistrict } from "@/graphql/district/query";
import { getListWard } from "@/graphql/ward/query";
import {
  fetchCityFailed,
  fetchCitySucceed,
  fetchDistrictFailed,
  fetchDistrictSucceed,
  fetchWardFailed,
  fetchWardSucceed
} from "@/store/redux/actions/AddressAction";
import AddressType from "@/store/redux/types/AddressType";
import { axios } from "@/utils/axiosInstance";
import { all, fork, put, takeLatest } from "redux-saga/effects";

function* fetchCitySaga(action: any) {
  try {
    let actionPayload = action.payload;
    let r = yield axios.post("/graphql", {
      query: getListCity(actionPayload.page, actionPayload.limit, actionPayload.filter)
    })
    let responsePayload = {
      citys: r.data.data.citys.edges,
      citys_length: r.data.data.citys.pageInfo.length,
    }
    yield put(fetchCitySucceed(responsePayload));
  } catch (e) {
    yield put(fetchCityFailed())
  }
}

function* fetchDistrictSaga(action: any) {
  try {
    let actionPayload = action.payload;
    let r = yield axios.post("/graphql", {
      query: getListDistrict(actionPayload.page, actionPayload.limit, actionPayload.filter)
    })
    let responsePayload = {
      districts: r.data.data.districts.edges,
      districts_length: r.data.data.districts.pageInfo.length,
    }
    yield put(fetchDistrictSucceed(responsePayload));
  } catch (e) {
    yield put(fetchDistrictFailed())
  }
}

function* fetchWardSaga(action: any) {
  try {
    let actionPayload = action.payload;
    let r = yield axios.post("/graphql", {
      query: getListWard(actionPayload.page, actionPayload.limit, actionPayload.filter)
    })
    let responsePayload = {
      wards: r.data.data.wards.edges,
      wards_length: r.data.data.wards.pageInfo.length,
    }
    yield put(fetchWardSucceed(responsePayload));
  } catch (e) {
    yield put(fetchWardFailed())
  }
}

function* watchAddressSaga() {
  yield takeLatest(AddressType.FETCH_CITY, fetchCitySaga);
  yield takeLatest(AddressType.FETCH_DISTRICT, fetchDistrictSaga);
  yield takeLatest(AddressType.FETCH_WARD, fetchWardSaga);
}

function* addressSaga() {
  yield all([
    fork(watchAddressSaga),
  ])
}

export default addressSaga;
