enum JobApplyType {
    FETCH_JOB_APPLY = "@@/JobApply/FETCH_JOB_APPLY",
    FETCH_JOB_APPLY_SUCCEED = "@@/JobApply/FETCH_JOB_APPLY_SUCCEED",
    FETCH_JOB_APPLY_FAILED = "@@/JobApply/FETCH_JOB_APPLY_FAILED",

    FETCH_LIST_JOB_APPLY = "@@/JobApply/FETCH_LIST_JOB_APPLY",
    FETCH_LIST_JOB_APPLY_SUCCEED = "@@/JobApply/FETCH_LIST_JOB_APPLY_SUCCEED",
    FETCH_LIST_JOB_APPLY_FAILED = "@@/JobApply/FETCH_LIST_JOB_APPLY_FAILED",

    UPDATE_JOB_APPLY = "@@/JobApply/UPDATE_JOB_APPLY",
    UPDATE_JOB_APPLY_SUCCEED = "@@/JobApply/UPDATE_JOB_APPLY_SUCCEED",
    UPDATE_JOB_APPLY_FAILED = "@@/JobApply/UPDATE_JOB_APPLY_FAILED",
}

interface JobApplyState {
    job_applys: any[];
    job_applys_length: number;
    job_applys_loading: boolean;

    job_apply: any,
    job_apply_loading: boolean;

    job_apply_update_loading: boolean;
}

export default JobApplyType;
export { JobApplyState };
