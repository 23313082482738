import { jobPostCreateMutation, jobPostUpdateMutation } from "@/graphql/job_post/mutation";
import { getListJobPost, getSinglePostById } from "@/graphql/job_post/query";
import { closeModal } from "@/store/redux/actions/GlobalModalAction";
import { enqueueSnackbar } from "@/store/redux/actions/GlobalToastAction";
import {
  createJobPostFailed,
  createJobPostSucceed, deleteJobPostFailed, deleteJobPostSucceed, fetchJobPostFailed, fetchJobPostSucceed,
  fetchListJobPostFailed, fetchListJobPostSucceed, updateJobPostFailed, updateJobPostSucceed
} from "@/store/redux/actions/JobPostAction";
import JobPostType from "@/store/redux/types/JobPostType";
import { axios } from "@/utils/axiosInstance";
import { all, fork, put, takeLatest } from "redux-saga/effects";


function* createJobPostSaga(action: any) {
  try {
    let actionPayload = action.payload;
    let r = yield axios.post("/graphql", {
      query: jobPostCreateMutation(),
      variables: actionPayload.variables,
    })
    const toast = {
      message: "Cập nhật thành công",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(createJobPostSucceed());
    yield (window.location.href = `/viec-lam/hoat-dong`);
  } catch (e) {
    const toast = {
      message: "Lỗi cập nhât máy chủ",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(createJobPostFailed());
  }
}

function* fetchJobPostSaga(action: any) {
  try {
    let actionPayload = action.payload;
    let r = yield axios.post("/graphql", {
      query: getSinglePostById(actionPayload._id)
    })
    let responsePayload = {
      job_post: r.data.data.jobPost
    }
    yield put(fetchJobPostSucceed(responsePayload));
  } catch (e) {
    yield put(fetchJobPostFailed());
  }
}

function* updateJobPostSaga(action: any) {
  try {
    let actionPayload = action.payload;
    let r = yield axios.post("/graphql", {
      query: jobPostUpdateMutation(),
      variables: actionPayload.variables,
    })
    const toast = {
      message: "Cập nhật thành công",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(updateJobPostSucceed());
    if (actionPayload.redirect) {
      yield window.location.href = actionPayload.redirect;
    } else {
      yield window.location.reload();
    }

  } catch (e) {
    const toast = {
      message: "Lỗi cập nhât máy chủ",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(updateJobPostFailed());
  }
}

function* deleteJobPostSaga(action: any) {
  try {
    let actionPayload = action.payload;
    let r = yield axios.post("/graphql", {
      query: jobPostUpdateMutation(),
      variables: actionPayload.variables,
    })
    const toast = {
      message: "Cập nhật thành công",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(deleteJobPostSucceed());
    yield window.location.reload();
  } catch (e) {
    const toast = {
      message: "Lỗi cập nhât máy chủ",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(deleteJobPostFailed());
  }
}

function* fetchListJobPostSaga(action: any) {
  try {
    let actionPayload = action.payload;
    let r = yield axios.post("/graphql", {
      query: getListJobPost(actionPayload.page, actionPayload.limit, actionPayload.filter)
    });

    let responsePayload = {
      job_posts: r.data.data.jobPosts.edges,
      job_posts_length: r.data.data.jobPosts.pageInfo.length,
    }

    yield put(fetchListJobPostSucceed(responsePayload));
  } catch (e) {
    yield put(fetchListJobPostFailed());
  }
}

function* watchFetchJobPostSaga() {
  yield takeLatest(JobPostType.CREATE_JOB_POST, createJobPostSaga);
  yield takeLatest(JobPostType.DELETE_JOB_POST, deleteJobPostSaga);
  yield takeLatest(JobPostType.FETCH_JOB_POST, fetchJobPostSaga);
  yield takeLatest(JobPostType.UPDATE_JOB_POST, updateJobPostSaga);
  yield takeLatest(JobPostType.FETCH_LIST_JOB_POST, fetchListJobPostSaga);
}

function* jobPostSaga() {
  yield all([fork(watchFetchJobPostSaga)]);
}

export default jobPostSaga;
