import JobPostType, { IJobPostState } from "@/store/redux/types/JobPostType";

const defaultState: IJobPostState = {
  job_posts: [],
  job_posts_loading: false,
  job_posts_length: 0,

  job_post_delete_loading: false,
  job_post_create_loading: false,
  job_post_update_loading: false,

  job_post: null,
  job_post_loading: false,

  detail: {},
  loading: false,
};
export const JobPostReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case JobPostType.FETCH_LIST_JOB_POST:
      return {
        ...state,
        job_posts: [],
        job_posts_length: 0,
        job_posts_loading: true,
      }
    case JobPostType.FETCH_LIST_JOB_POST_SUCCEED:
      return {
        ...state,
        job_posts_loading: false,
        job_posts: action.payload.job_posts,
        job_posts_length: action.payload.job_posts_length,
      }
    case JobPostType.FETCH_LIST_JOB_POST_FAILED:
      return {
        ...state,
        job_posts: [],
        job_posts_length: 0,
        job_posts_loading: false,
      }
    case JobPostType.CREATE_JOB_POST:
      return {
        ...state,
        job_post_create_loading: true,
      };
    case JobPostType.CREATE_JOB_POST_SUCCEED:
      return {
        ...state,
        job_post_create_loading: false,
      };
    case JobPostType.CREATE_JOB_POST_FAILED:
      return {
        ...state,
        job_post_create_loading: false,
      };

    case JobPostType.DELETE_JOB_POST:
      return {
        ...state,
        job_post_delete_loading: true,
      };
    case JobPostType.DELETE_JOB_POST_SUCCEED:
      return {
        ...state,
        job_post_delete_loading: false,
      };
    case JobPostType.DELETE_JOB_POST_FAILED:
      return {
        ...state,
        job_post_delete_loading: false,
      };

    case JobPostType.FETCH_JOB_POST:
      return {
        ...state,
        job_post: null,
        job_post_loading: true,
      };
    case JobPostType.FETCH_JOB_POST_SUCCEED:
      return {
        ...state,
        job_post_loading: false,
        job_post: action.payload.job_post,
      };
    case JobPostType.FETCH_JOB_POST_FAILED:
      return {
        ...state,
        job_post: null,
        job_post_loading: false,
      };
    case JobPostType.UPDATE_JOB_POST:
      return {
        ...state,
        job_post_update_loading: true,
      };
    case JobPostType.UPDATE_JOB_POST_SUCCEED:
      return {
        ...state,
        job_post_update_loading: false,
      };
    case JobPostType.UPDATE_JOB_POST_FAILED:
      return {
        ...state,
        job_post_update_loading: false,
      };
    default:
      return state;
  }
};
