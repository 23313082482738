enum JobTitleType {
  FETCH_JOB_TITLE = "@@/JobTitle/FETCH_JOB_TITLE",
  FETCH_JOB_TITLE_SUCCEED = "@@/JobTitle/FETCH_JOB_TITLE_SUCCEED",
  FETCH_JOB_TITLE_FAILED = "@@/JobTitle/FETCH_JOB_TITLE_FAILED",
}

interface IJobTitleState {
  job_title: any[];
  loading: boolean;
}

export default JobTitleType;
export {IJobTitleState};
