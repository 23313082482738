import { AddressReducer } from "@/store/redux/reducers/AddressReducer";
import { ApplyListDetailReducer } from "@/store/redux/reducers/ApplyListDetailReducer";
import { ApplyListReducer } from "@/store/redux/reducers/ApplyListReducer";
import { CandidateReducer } from "@/store/redux/reducers/CandidateReducer";
import { CompanyReducer } from "@/store/redux/reducers/CompanyReducer";
import { CoordinateReducer } from "@/store/redux/reducers/CoordinateReducer";
import { GlobalModalReducer } from "@/store/redux/reducers/GlobalModalReducer";
import { GlobalToastReducer } from "@/store/redux/reducers/GlobalToastReducer";
import { JobCategoryReducer } from "@/store/redux/reducers/JobCategoryReducer";
import { JobLevelReducer } from "@/store/redux/reducers/JobLevelReducer";
import { JobPostReducer } from "@/store/redux/reducers/JobPostReducer";
import { JobTitleReducer } from "@/store/redux/reducers/JobTitleReducer";
import { JobTypeReducer } from "@/store/redux/reducers/JobTypeReducer";
import { ListCandidateReducer } from "@/store/redux/reducers/ListCandidateReducer";
import { ListCompanyReducer } from "@/store/redux/reducers/ListCompanyReducer";
import { LoadingAppReducer } from "@/store/redux/reducers/LoadingAppReducer";
import { NotificationReducer } from "@/store/redux/reducers/NotificationReducer";
import { UserReducer } from "@/store/redux/reducers/UserReducer";
import { JobApplyReducer } from "@/store/redux/reducers/JobApplyReducer";
import { WarehouseReducer } from "@/store/redux/reducers/WarehouseReducer";
import { CreditReducer } from "@/store/redux/reducers/CreditReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  listCompany: ListCompanyReducer,
  company: CompanyReducer,
  applyList: ApplyListReducer,
  applyDetail: ApplyListDetailReducer,
  globalToast: GlobalToastReducer,
  globalModal: GlobalModalReducer,
  coordinate: CoordinateReducer,
  jobCategory: JobCategoryReducer,
  jobTitle: JobTitleReducer,
  jobType: JobTypeReducer,
  jobLevel: JobLevelReducer,
  address: AddressReducer,
  notification: NotificationReducer,
  jobPost: JobPostReducer,
  candidate: CandidateReducer,
  listCandidate: ListCandidateReducer,
  loadingApp: LoadingAppReducer,
  user: UserReducer,
  jobApply: JobApplyReducer,
  warehouse: WarehouseReducer,
  credit: CreditReducer
});

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer;
