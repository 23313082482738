import { setPage } from "@/store/redux/actions/ApplyListAction";
import { all, fork, put } from "redux-saga/effects";


function* fetchJobApplySaga(action: any) {
  try {
    yield put(setPage(action.payload.page));
    // const r = yield apolloClient.query({ query: PAGINATE_JOB_APPLY, variables: action.payload });
    // yield put(fetchJobApplySuccess(r.data.jobApplys));
  } catch (err) {
    console.log(err);
  }
}

function* watchApplyListSaga() {
  // yield takeLatest(ApplyListType.FETCH_JOB_APPLY, fetchJobApplySaga);
}

function* ApplyListSaga() {
  yield all([fork(watchApplyListSaga)]);
}

export default ApplyListSaga;
