export const cvWarehouseCreateMutation = () => `
  mutation cvWarehouseCreate($input: CvWarehouseInput!) {
    cvWarehouseCreate(input: $input) {
      _id
    }
  }
`;
export const cvWarehouseUpdateMutation = () => `
    mutation cvWarehouseUpdate($input: CvWarehouseInput!) {
      cvWarehouseUpdate(input: $input) {
        _id
      }
    }
`;