export const getListCvWarehouse = (page: number, limit: number, filter: string) => `
    query {
        cvWarehouses(page: ${page}, limit: ${limit}, filter: "${filter}") {
            edges {
                node {
                    _id
                    thumnail
                    title
                    description
                    created_at
                    updated_at
                }
            }
            pageInfo {
                length
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

export const getCvWarehouseById = (_id: string) => `
    query {
        cvWarehouse(_id: "${_id}") {
            _id
            thumnail
            title
            description
            created_at
            updated_at
        }
    }
`;
