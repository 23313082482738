enum ListCandidateType {
  FETCH_LIST_CANDIDATE = "@@/ListCandidate/FETCH_LIST_CANDIDATE",
  FETCH_LIST_CANDIDATE_SUCCEED = "@@/ListCandidate/FETCH_LIST_CANDIDATE_SUCCEED",
  FETCH_LIST_CANDIDATE_FAILED = "@@/ListCandidate/FETCH_LIST_CANDIDATE_FAILED",
}

interface IListCandidateState {
  candidate: [];
  length: number;
  loading: boolean;
}

export default ListCandidateType;
export {IListCandidateState};
