import CreditType from "@/store/redux/types/CreditType";

export const fetchCredit = () => ({
  type: CreditType.FETCH_CREDIT
});

export const fetchCreditSucceed = (payload: any) => ({
  type: CreditType.FETCH_CREDIT_SUCCEED,
  payload
})

export const fetchCreditFailed = () => ({
  type: CreditType.FETCH_CREDIT_FAILED
})
