import AddressType, { IAddressState } from "@/store/redux/types/AddressType";

const defaultState: IAddressState = {
  citys: [],
  citys_length: 0,
  citys_loading: false,
  districts: [],
  districts_length: 0,
  districts_loading: false,
  wards: [],
  wards_length: 0,
  wards_loading: false
};

export const AddressReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case AddressType.FETCH_CITY:
      return {
        ...state,
        citys_loading: true,
      };
    case AddressType.FETCH_CITY_SUCCEED:
      return {
        ...state,
        citys: action.payload.citys,
        citys_length: action.payload.citys_length,
        citys_loading: false,
        districts: [],
        wards: [],
      };
    case AddressType.FETCH_CITY_FAILED:
      return {
        ...state,
        citys_loading: false,
      };

    case AddressType.FETCH_DISTRICT:
      return {
        ...state,
        districts_loading: true,
      };
    case AddressType.FETCH_DISTRICT_SUCCEED:
      return {
        ...state,
        districts: action.payload.districts,
        districts_length: action.payload.districts_length,
        districts_loading: false,
        wards: [],
      };
    case AddressType.FETCH_DISTRICT_FAILED:
      return {
        ...state,
        districts_loading: false,
      };

    case AddressType.FETCH_WARD:
      return {
        ...state,
        wards_loading: true,
      };
    case AddressType.FETCH_WARD_SUCCEED:
      return {
        ...state,
        wards: action.payload.wards,
        wards_loading: false,
        wards_length: action.payload.wards_length,
      };
    case AddressType.FETCH_WARD_FAILED:
      return {
        ...state,
        wards_loading: false,
      };
    default:
      return state;
  }
};
