import CandidateType from "@/store/redux/types/CandidateType";

export const fetchCandidate = (payload: any) => ({
  type: CandidateType.FETCH_CANDIDATE,
  payload
});

export const fetchCandidateSucceed = (payload: any) => ({
  type: CandidateType.FETCH_CANDIDATE_SUCCEED,
  payload
});

export const fetchCandidateFailed = () => ({
  type: CandidateType.FETCH_CANDIDATE_FAILED,
});

export const openCandidate = (payload: any) => ({
  type: CandidateType.OPEN_CANDIDATE,
  payload
});

export const openCandidateSucceed = (payload: any) => ({
  type: CandidateType.OPEN_CANDIDATE_SUCCEED,
  payload
});

export const openCandidateFailed = () => ({
  type: CandidateType.OPEN_CANDIDATE_FAILED,
});


export const createCandidate = (payload: any) => ({
  type: CandidateType.CREATE_CANDIDATE,
  payload
});

export const createCandidateSucceed = (payload: any) => ({
  type: CandidateType.CREATE_CANDIDATE_SUCCEED,
  payload
});

export const createCandidateFailed = () => ({
  type: CandidateType.CREATE_CANDIDATE_FAILED,
});


export const updateCandidate = (payload: any) => ({
  type: CandidateType.UPDATE_CANDIDATE,
  payload
});

export const updateCandidateSucceed = (payload: any) => ({
  type: CandidateType.UPDATE_CANDIDATE_SUCCEED,
  payload
});

export const updateCandidateFailed = () => ({
  type: CandidateType.UPDATE_CANDIDATE_FAILED,
});



export const fetchListCandidate = (payload: any) => ({
  type: CandidateType.FETCH_LIST_CANDIDATE,
  payload,
});

export const fetchListCandidateSucceed = (payload: any) => ({
  type: CandidateType.FETCH_LIST_CANDIDATE_SUCCEED,
  payload,
});

export const fetchListCandidateFailed = () => ({
  type: CandidateType.FETCH_LIST_CANDIDATE_FAILED,
});

export const fetchListOpenCandidate = (payload: any) => ({
  type: CandidateType.FETCH_LIST_OPEN_CANDIDATE,
  payload,
});

export const fetchListOpenCandidateSucceed = (payload: any) => ({
  type: CandidateType.FETCH_LIST_OPEN_CANDIDATE_SUCCEED,
  payload,
});

export const fetchListOpenCandidateFailed = () => ({
  type: CandidateType.FETCH_LIST_OPEN_CANDIDATE_FAILED,
});
