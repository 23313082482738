import ListCandidateType from "@/store/redux/types/ListCandidateType";

export const fetchListCandidate = (payload: any) => ({
  type: ListCandidateType.FETCH_LIST_CANDIDATE,
  payload
});

export const fetchListCandidateSucceed = (payload: any) => ({
  type: ListCandidateType.FETCH_LIST_CANDIDATE_SUCCEED,
  payload
});

export const fetchListCandidateFailed = () => ({
  type: ListCandidateType.FETCH_LIST_CANDIDATE_FAILED,
});
