import JobLevelType, { IJobLevelState } from "@/store/redux/types/JobLevelType";

const defaultState: IJobLevelState = {
  job_levels: [],
  job_levels_loading: false,
  job_levels_length: 0,
};

export const JobLevelReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case JobLevelType.FETCH_JOB_LEVEL:
      return {
        ...state,
        job_levels_loading: true,
      };
    case JobLevelType.FETCH_JOB_LEVEL_SUCCEED:
      return {
        ...state,
        job_levels: action.payload.job_levels,
        job_levels_length: action.payload.job_levels_length,
        job_levels_loading: false
      };
    case JobLevelType.FETCH_JOB_LEVEL_FAILED:
      return {
        ...state,
        job_levels_loading: false
      };
    default:
      return state;
  }
};
