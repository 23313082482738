import WarehouseType from "@/store/redux/types/WarehouseType";

export const createWarehouse = (payload: any) => ({
    type: WarehouseType.CREATE_WAREHOUSE,
    payload,
});

export const createWarehouseSucceed = () => ({
    type: WarehouseType.CREATE_WAREHOUSE_SUCCEED,
});

export const createWarehouseFailed = () => ({
    type: WarehouseType.CREATE_WAREHOUSE_FAILED
});

export const deleteWarehouse = (payload: any) => ({
    type: WarehouseType.DELETE_WAREHOUSE,
    payload,
});

export const deleteWarehouseSucceed = () => ({
    type: WarehouseType.DELETE_WAREHOUSE_SUCCEED,
});

export const deleteWarehouseFailed = () => ({
    type: WarehouseType.DELETE_WAREHOUSE_FAILED
});

export const fetchWarehouse = (payload: any) => ({
    type: WarehouseType.FETCH_WAREHOUSE,
    payload,
});

export const fetchWarehouseSucceed = (payload: any) => ({
    type: WarehouseType.FETCH_WAREHOUSE_SUCCEED,
    payload,
});

export const fetchWarehouseFailed = () => ({
    type: WarehouseType.FETCH_WAREHOUSE_FAILED
});


export const updateWarehouse = (payload: any) => ({
    type: WarehouseType.UPDATE_WAREHOUSE,
    payload,
});

export const updateWarehouseSucceed = () => ({
    type: WarehouseType.UPDATE_WAREHOUSE_SUCCEED
});
export const updateWarehouseFailed = () => ({
    type: WarehouseType.UPDATE_WAREHOUSE_FAILED,
});


export const fetchListWarehouse = (payload: any) => ({
    type: WarehouseType.FETCH_LIST_WAREHOUSE,
    payload,
});

export const fetchListWarehouseSucceed = (payload: any) => ({
    type: WarehouseType.FETCH_LIST_WAREHOUSE_SUCCEED,
    payload,
});

export const fetchListWarehouseFailed = () => ({
    type: WarehouseType.FETCH_LIST_WAREHOUSE_FAILED
});



export const createCv = (payload: any) => ({
    type: WarehouseType.CREATE_CV,
    payload,
});

export const createCvSucceed = () => ({
    type: WarehouseType.CREATE_CV_SUCCEED,
});

export const createCvFailed = () => ({
    type: WarehouseType.CREATE_CV_FAILED
});

export const deleteCv = (payload: any) => ({
    type: WarehouseType.DELETE_CV,
    payload,
});

export const deleteCvSucceed = () => ({
    type: WarehouseType.DELETE_CV_SUCCEED,
});

export const deleteCvFailed = () => ({
    type: WarehouseType.DELETE_CV_FAILED
});

export const fetchCv = (payload: any) => ({
    type: WarehouseType.FETCH_CV,
    payload,
});

export const fetchCvSucceed = (payload: any) => ({
    type: WarehouseType.FETCH_CV_SUCCEED,
    payload,
});

export const fetchCvFailed = () => ({
    type: WarehouseType.FETCH_CV_FAILED
});


export const updateCv = (payload: any) => ({
    type: WarehouseType.UPDATE_CV,
    payload,
});

export const updateCvSucceed = () => ({
    type: WarehouseType.UPDATE_CV_SUCCEED
});
export const updateCvFailed = () => ({
    type: WarehouseType.UPDATE_CV_FAILED,
});


export const fetchListCv = (payload: any) => ({
    type: WarehouseType.FETCH_LIST_CV,
    payload,
});

export const fetchListCvSucceed = (payload: any) => ({
    type: WarehouseType.FETCH_LIST_CV_SUCCEED,
    payload,
});

export const fetchListCvFailed = () => ({
    type: WarehouseType.FETCH_LIST_CV_FAILED
});

