import CreditType from "@/store/redux/types/CreditType";

import { all, delay, fork, put, takeLatest } from "redux-saga/effects";

import { axios } from "@/utils/axiosInstance";
import {fetchCreditFailed, fetchCreditSucceed} from "@/store/redux/actions/CreditAction";

function* fetchCredit(action: any) {
  try {
     let r = yield axios.get(`${process.env.REACT_APP_DATA_API_URL}/employer/credits`);
     let responsePayload = {
       credit: r.data.data
     }
     yield put(fetchCreditSucceed(responsePayload))
  } catch (e) {
    yield put(fetchCreditFailed());
  }
}

function* watchCreditSaga() {
  yield takeLatest(CreditType.FETCH_CREDIT, fetchCredit);
}

function* creditSaga() {
  yield all([
    fork(watchCreditSaga)
  ])
}
export default creditSaga;
