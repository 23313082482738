import gql from "graphql-tag";

export const PAGINATE_JOB_TITLE = gql`
  query jobTitles($page: Int!, $limit: Int!, $filter: String!) {
    jobTitles(page: $page, filter: $filter, limit: $limit) {
      edges {
        node {
          _id
          title
        }
      }
    }
  }
`;

export const JOB_TITLE = gql`
  query jobTitle($id: String!) {
    jobTitle(_id: $id) {
      _id
      title
    }
  }
`;
