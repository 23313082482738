import JobLevelType from "@/store/redux/types/JobLevelType";

export const fetchJobLevel = (payload: any) => ({
  type: JobLevelType.FETCH_JOB_LEVEL,
  payload
});

export const fetchJobLevelSucceed = (payload: any) => ({
  type: JobLevelType.FETCH_JOB_LEVEL_SUCCEED,
  payload
});

export const fetchJobLevelFailed = () => ({
  type: JobLevelType.FETCH_JOB_LEVEL_FAILED,
});
