import { updateJobApplyMutations } from "@/graphql/job_apply/mutation";
import { getListJobApply, getSingleJobApplyById } from "@/graphql/job_apply/query";
import { fetchJobApplyFailed, fetchJobApplySucceed, fetchListJobApplyFailed, fetchListJobApplySucceed, updateJobApplyFailed, updateJobApplySucceed } from "@/store/redux/actions/JobApplyAction";
import JobApplyType from "@/store/redux/types/JobApplyType";
import { axios } from "@/utils/axiosInstance";
import { all, fork, put, takeLatest } from "redux-saga/effects";
import { enqueueSnackbar } from "../../actions/GlobalToastAction";

function* fetchListJobApplySaga(action: any) {
    try {
        let actionPayload = action.payload;
        let r = yield axios.post("/graphql", {
            query: getListJobApply(actionPayload.page, actionPayload.limit, actionPayload.filter)
        })
        let responsePayload = {
            job_applys: r.data.data.employerJobApplys.edges,
            job_applys_length: r.data.data.employerJobApplys.pageInfo.length
        }
        yield put(fetchListJobApplySucceed(responsePayload));
    } catch (err) {
        yield put(fetchListJobApplyFailed());
    }
}

function* fetchJobApplySaga(action: any) {
    try {
        let actionPayload = action.payload;
        let r = yield axios.post("/graphql", {
            query: getSingleJobApplyById(actionPayload._id)
        })
        let responsePayload = {
            job_apply: r.data.data.employerJobApply,
        }
        yield put(fetchJobApplySucceed(responsePayload));
    } catch (err) {
        yield put(fetchJobApplyFailed());
    }
}

function* updateJobApplySaga(action: any) {
    try {
        let actionPayload = action.payload;
        let r = yield axios.post("/graphql", {
            query: updateJobApplyMutations(),
            variables: actionPayload.variables,
        })
        yield put(updateJobApplySucceed());
        const toast = {
            message: "Cập nhật thành công",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
            },
        };
        yield put(enqueueSnackbar(toast));
        if (actionPayload.redirect) {
            yield window.location.href = actionPayload.redirect;
        } else {
            yield window.location.reload();
        }
    } catch (err) {
        const toast = {
            message: "Lỗi cập nhât máy chủ",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
            },
        };
        yield put(enqueueSnackbar(toast));
        yield put(updateJobApplyFailed());
    }
}

function* watchJobApplySaga() {
    yield takeLatest(JobApplyType.FETCH_LIST_JOB_APPLY, fetchListJobApplySaga);
    yield takeLatest(JobApplyType.FETCH_JOB_APPLY, fetchJobApplySaga);
    yield takeLatest(JobApplyType.UPDATE_JOB_APPLY, updateJobApplySaga);
}

function* JobApplySaga() {
    yield all([fork(watchJobApplySaga)]);
}

export default JobApplySaga;
