enum ListJobPostType {
  FETCH_LIST_JOB_POST = "@@ListJobPost/FETCH_LIST_JOB_POST",
  FETCH_LIST_JOB_POST_SUCCEED = "@@ListJobPost/FETCH_LIST_JOB_POST_SUCCEED",
  FETCH_LIST_JOB_POST_FAILED = "@@ListJobPost/FETCH_LIST_JOB_POST_FAILED",
}

interface IListJobPostState {
  loading: boolean,
  job_post: any[];
}

export default ListJobPostType;
export {IListJobPostState};
