import ApplyListDetailType from "@/store/redux/types/ApplyListDetailType";

export const fetchJobApplyDetail = (payload: any) => ({
  type: ApplyListDetailType.FETCH_JOB_APPLY_DETAIL,
  payload,
});

export const fetchJobApplyDetailSuccess = (payload: any) => ({
  type: ApplyListDetailType.FETCH_JOB_APPLY_DETAIL_SUCCESS,
  payload,
});

export const fetchJobApplyDetailFailure = (payload: any) => ({
  type: ApplyListDetailType.FETCH_JOB_APPLY_DETAIL_FAILURE,
  payload,
});

export const updateJobApply = (payload: any) => ({
  type: ApplyListDetailType.UPDATE_JOB_APPLY_DETAIL,
  payload,
});

export const updateJobApplySuccess = (payload: any) => ({
  type: ApplyListDetailType.UPDATE_JOB_APPLY_DETAIL_SUCCESS,
  payload,
});

export const updateJobApplyFailure = (payload: any) => ({
  type: ApplyListDetailType.UPDATE_JOB_APPLY_DETAIL_FAILURE,
  payload,
});

export const sendEmail = (payload: any) => ({
  type: ApplyListDetailType.SEND_EMAIL,
  payload,
});

export const sendEmailSuccess = () => ({
  type: ApplyListDetailType.SEND_EMAIL_SUCCESS,
});

export const sendEmailFailure = () => ({
  type: ApplyListDetailType.SEND_EMAIL_FAILURE,
});
