import CompanyType, { ICompanyState } from "@/store/redux/types/CompanyType";

const defaultState: ICompanyState = {
  companys: [],
  companys_length: 0,
  companys_loading: false,

  company_loading: false,
  company: null,

  company_create_loading: false,

  company_update_loading: false,

  detail: {
    _id: "",
    name: "",
    business_code: "",
    slug: "",
    verify_status: false,
    logo: "",
    cover: "",
    website: "",
    phone: "",
    follow: "",
    facebook: "",
    youtube: "",
    description: "",
    slogan: "",
    album: [],
    benefit: [],
    story: [],
    job_category: {
      _id: "",
      title: "",
      slug: "",
    },
    created_by: {
      first_name: "",
      last_name: "",
      avatar: "",
    },
    office: [],
    people: [],
  },
  loading: false,
};
export const CompanyReducer = (state = defaultState, action: any) => {
  switch (action.type) {

    case CompanyType.FETCH_LIST_COMPANY:
      return {
        ...state,
        companys_loading: true,
      }
    case CompanyType.FETCH_LIST_COMPANY_SUCCEED:
      return {
        ...state,
        companys: action.payload.companys,
        companys_loading: false,
        companys_length: action.payload.companys_length
      }
    case CompanyType.FETCH_LIST_COMPANY_FAILED:
      return {
        ...state,
        companys_loading: false,
      }

    case CompanyType.CREATE_COMPANY:
      return {
        ...state,
        company_create_loading: true,
      };
    case CompanyType.CREATE_COMPANY_SUCCEED:
      return {
        ...state,
        company_create_loading: false,
      };
    case CompanyType.CREATE_COMPANY_FAILED:
      return {
        ...state,
        company_create_loading: false,
      };
    case CompanyType.FETCH_COMPANY:
      return {
        ...state,
        company: null,
        company_loading: true,
      };
    case CompanyType.FETCH_COMPANY_SUCCEED:
      return {
        ...state,
        company_loading: false,
        company: action.payload.company,
      };
    case CompanyType.FETCH_COMPANY_FAILED:
      return {
        ...state,
        company: null,
        company_loading: false,
      };
    case CompanyType.UPDATE_COMPANY:
      return {
        ...state,
        company_update_loading: true,
      };
    case CompanyType.UPDATE_COMPANY_SUCCEED:
      return {
        ...state,
        company_update_loading: false,
      };

    case CompanyType.UPDATE_COMPANY_FAILED:
      return {
        ...state,
        company_update_loading: false,
      };
    default:
      return state;
  }
};
