import React, { ComponentType, lazy, ReactNode } from "react";
import { Redirect, RouteProps } from "react-router-dom";

export interface AppRoute {
  path: string;
  exact: boolean;
  component: ComponentType;
}

export interface AppRouteList extends Array<AppRoute> { };

const routes: AppRouteList = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/tong-quan" />,
  },
  {
    path: "/dang-nhap",
    exact: true,
    component: lazy(() => import("@/views/LoginPage")),
  },
  {
    path: "/notification",
    exact: true,
    component: lazy(() => import("@/views/Notification/Notification")),
  },
  {
    path: `/tong-quan`,
    exact: true,
    component: lazy(() => import("@/views/OverviewPage/OverviewPage")),
  },
  {
    path: `/bang-gia`,
    exact: true,
    component: lazy(() => import("@/views/Pricing/PricingPage/PricingPage")),
  },
  {
    path: `/thanh-toan`,
    exact: true,
    component: lazy(() => import("@/views/Pricing/CheckoutPage/CheckoutPage")),
  },
  {
    path: `/lich-su-don-hang`,
    exact: true,
    component: lazy(() => import("@/views/Pricing/HistoryOrderPage/HistoryOrderPage")),
  },
  {
    path: `/don-hang/:id`,
    exact: true,
    component: lazy(() => import("@/views/Pricing/OrderPage/OrderPage")),
  },
  {
    path: `/viec-lam/tao-moi`,
    exact: true,
    component: lazy(() => import("@/views/Job/JobCreate/JobCreateFull")),
  },
  {
    path: `/viec-lam/hoat-dong`,
    exact: true,
    component: lazy(() => import("@/views/Job/JobList/JobList")),
  },
  {
    path: `/viec-lam/het-han`,
    exact: true,
    component: lazy(() => import("@/views/Job/JobListExpire/JobListExpire")),
  },
  {
    path: `/viec-lam/thung-rac`,
    exact: true,
    component: lazy(() => import("@/views/Job/JobListTrash/JobListTrash")),
  },
  {
    path: `/viec-lam/:slug`,
    exact: true,
    component: lazy(() => import("@/views/Job/JobEdit/JobEdit")),
  },
  {
    path: `/ung-tuyen/dang-cho`,
    exact: true,
    component: lazy(() => import("@/views/Apply/JobApply/ApplyPending")),
  },
  {
    path: `/ung-tuyen/da-duyet`,
    exact: true,
    component: lazy(() => import("@/views/Apply/JobApply/ApplyApprove")),
  },
  {
    path: `/ung-tuyen/tu-choi`,
    exact: true,
    component: lazy(() => import("@/views/Apply/JobApply/ApplyDecline")),
  },
  {
    path: `/ung-tuyen/:slug`,
    exact: true,
    component: lazy(() => import("@/views/Apply/JobApplyDetail/ApplyDetail")),
  },
  {
    path: "/tim-ung-vien/kho",
    exact: true,
    component: lazy(() => import("@/views/Candidate/FindCandidate/FindCandidate")),
  },
  {
    path: "/tim-ung-vien/da-mo",
    exact: true,
    component: lazy(() => import("@/views/Candidate/OpenCandidate/OpenCandidate")),
  },
  {
    path: "/tim-ung-vien/:slug",
    exact: true,
    component: lazy(() => import("@/views/Candidate/CandidateDetail/CandidateDetail")),
  },
  {
    path: "/fb-connect",
    exact: true,
    component: lazy(() => import("@/views/Integration/FbConnect/FbConnect")),
  },
  {
    path: "/orders",
    exact: true,
    component: lazy(() => import("@/views/Order/OrderList/OrderList")),
  },
  {
    path: "/orders/:slug",
    exact: true,
    component: lazy(() => import("@/views/Order/OrderDetail/OrderDetail")),
  },
  {
    path: "/cong-ty/danh-sach",
    exact: true,
    component: lazy(() => import("@/views/Company/CompanyList/CompanyList")),
  },
  {
    path: "/cong-ty/tao-moi",
    exact: true,
    component: lazy(() => import("@/views/Company/CompanyCreate/CompanyCreate")),
  },

  {
    path: "/cong-ty/:slug/phan-quyen/tao-moi",
    exact: true,
    component: lazy(() => import("@/views/CompanyPermission/Components/CreatePermisstion")),
  },
  {
    path: "/cong-ty/:slug/phan-quyen/danh-sach",
    exact: true,
    component: lazy(() => import("@/views/CompanyPermission/CompanyPermission")),
  },
  {
    path: "/cong-ty/:slug/van-phong",
    exact: false,
    component: lazy(() => import("@/views/Company/CompanyDetail/Office/Office")),
  },
  {
    path: "/cong-ty/:slug/con-nguoi",
    exact: false,
    component: lazy(() => import("@/views/Company/CompanyDetail/People/People")),
  },
  {
    path: "/cong-ty/:slug/hinh-anh",
    exact: false,
    component: lazy(() => import("@/views/Company/CompanyDetail/Media/Media")),
  },
  {
    path: "/cong-ty/:slug/phuc-loi",
    exact: false,
    component: lazy(() => import("@/views/Company/CompanyDetail/Benefit/Benefit")),
  },
  {
    path: "/cong-ty/:slug/cau-chuyen",
    exact: false,
    component: lazy(() => import("@/views/Company/CompanyDetail/Story/Story")),
  },
  {
    path: "/cong-ty/:slug/cai-dat",
    exact: false,
    component: lazy(() => import("@/views/Company/CompanyDetail/InterfaceInfo/InterfaceInfo")),
  },
  {
    path: "/cong-ty/:slug",
    exact: false,
    component: lazy(() => import("@/views/Company/CompanyDetail/Dashboard/Dashboard")),
  },
  {
    path: "/kho-cv/danh-sach",
    exact: true,
    component: lazy(() => import("@/views/Warehouse/List/List")),
  },
  {
    path: "/kho-cv/tao-moi",
    exact: true,
    component: lazy(() => import("@/views/Warehouse/Create/Create")),
  },
  {
    path: "/kho-cv/:slug",
    exact: true,
    component: lazy(() => import("@/views/Warehouse/Edit/Edit")),
  },
  {
    path: "/kho-cv/:slug/danh-sach",
    exact: true,
    component: lazy(() => import("@/views/Warehouse/CV/List/List")),
  },
  {
    path: "/kho-cv/:slug/them-cv",
    exact: true,
    component: lazy(() => import("@/views/Warehouse/CV/Create/Create")),
  },
  {
    path: "/kho-cv/:slug/sua-cv/:cv",
    exact: true,
    component: lazy(() => import("@/views/Warehouse/CV/Edit/Edit")),
  },
  {
    path: "/cv/upload",
    exact: true,
    component: lazy(() => import("@/views/CV/Upload/Upload")),
  },
  {
    path: "/cv/list",
    exact: true,
    component: lazy(() => import("@/views/CV/List/List")),
  },
  {
    path: "/cv/:slug",
    exact: true,
    component: lazy(() => import("@/views/CV/Edit/Edit")),
  },
];

export default routes;
