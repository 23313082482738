import JobApplyType from "@/store/redux/types/JobApplyType";

export const fetchListJobApply = (payload: any) => ({
    type: JobApplyType.FETCH_LIST_JOB_APPLY,
    payload
});

export const fetchListJobApplySucceed = (payload: any) => ({
    type: JobApplyType.FETCH_LIST_JOB_APPLY_SUCCEED,
    payload,
});

export const fetchListJobApplyFailed = () => ({
    type: JobApplyType.FETCH_LIST_JOB_APPLY_FAILED,
});


export const fetchJobApply = (payload: any) => ({
    type: JobApplyType.FETCH_JOB_APPLY,
    payload,
});

export const fetchJobApplySucceed = (payload: any) => ({
    type: JobApplyType.FETCH_JOB_APPLY_SUCCEED,
    payload,
});

export const fetchJobApplyFailed = () => ({
    type: JobApplyType.FETCH_JOB_APPLY_FAILED,
});

export const updateJobApply = (payload: any) => ({
    type: JobApplyType.UPDATE_JOB_APPLY,
    payload,
});

export const updateJobApplySucceed = () => ({
    type: JobApplyType.UPDATE_JOB_APPLY_SUCCEED,
});

export const updateJobApplyFailed = () => ({
    type: JobApplyType.UPDATE_JOB_APPLY_FAILED,
});