import ApplyListType from "@/store/redux/types/ApplyListType";

export const fetchJobApply = (payload: any) => ({
  type: ApplyListType.FETCH_JOB_APPLY,
  payload,
});

export const fetchJobApplySuccess = (payload: any) => ({
  type: ApplyListType.FETCH_JOB_APPLY_SUCCESS,
  payload,
});

export const fetchJobApplyFailure = (payload: any) => ({
  type: ApplyListType.FETCH_JOB_APPLY_FAILURE,
  payload,
});

export const setConfirm = (payload: any) => ({
  type: ApplyListType.SET_CONFIRM,
  payload,
});
export const setQuickView = (payload: any) => ({
  type: ApplyListType.SET_QUICK_VIEW,
  payload,
});
export const setPage = (payload: any) => ({
  type: ApplyListType.SET_PAGE,
  payload,
});
