import JobApplyType, { JobApplyState } from "@/store/redux/types/JobApplyType";
const defaultState: JobApplyState = {
    job_applys: [],
    job_applys_loading: false,
    job_applys_length: 0,

    job_apply: null,
    job_apply_loading: false,

    job_apply_update_loading: false,
};
export const JobApplyReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case JobApplyType.FETCH_JOB_APPLY:
            return {
                ...state,
                job_apply: null,
                job_apply_loading: true,
            };
        case JobApplyType.FETCH_JOB_APPLY_SUCCEED:
            return {
                ...state,
                job_apply_loading: false,
                job_apply: action.payload.job_apply,
            };
        case JobApplyType.FETCH_JOB_APPLY_FAILED:
            return {
                ...state,
                job_apply: null,
                job_apply_loading: false,
            };

        case JobApplyType.FETCH_LIST_JOB_APPLY:
            return {
                ...state,
                job_applys: [],
                job_applys_length: 0,
                job_applys_loading: true,
            };
        case JobApplyType.FETCH_LIST_JOB_APPLY_SUCCEED:
            return {
                ...state,
                job_applys_loading: false,
                job_applys: action.payload.job_applys,
                job_applys_length: action.payload.job_applys_length,
            };
        case JobApplyType.FETCH_LIST_JOB_APPLY_FAILED:
            return {
                ...state,
                job_applys: [],
                job_applys_length: 0,
                job_applys_loading: false,
            };

        case JobApplyType.UPDATE_JOB_APPLY:
            return {
                ...state,
                job_apply_update_loading: true,
            };
        case JobApplyType.UPDATE_JOB_APPLY_SUCCEED:
            return {
                ...state,
                job_apply: false,
            };
        case JobApplyType.UPDATE_JOB_APPLY_FAILED:
            return {
                ...state,
                job_apply_update_loading: false,
            };
        default:
            return state;
    }
};
