
export const createCompanyMutation = () => `
    mutation createChannel ($input: CompanyInput!) {
        companyCreate (input: $input) {
            _id
        }
    }
`;
export const updateCompanyMutation = () => `
    mutation updateBasic($input: CompanyInput!) {
        companyUpdate(input: $input) {
            _id
            name
            slug
            verify_status
            logo
            cover
            website
            phone
            follow
            facebook
            youtube
            description
            slogan
            album {
                name
                images {
                    src
                    description
                }
            }
            benefit {
                id {
                    _id
                    title
                }
                content
            }
            story {
                title
                content
                media_type
                media_link
            }
            job_category {
                _id
                title
            }
            created_by {
                first_name
                last_name
                avatar
            }
            office {
                city {
                    _id
                    name
                }
                district {
                    _id
                    name
                }
                ward {
                    _id
                    name
                }
                address
                lat
                lng
            }
            people {
                name
                content
                position
                avatar
            }
        }
    }
`;
