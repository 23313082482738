import CandidateType, { ICandidateState } from "@/store/redux/types/CandidateType";

const defaultState: ICandidateState = {
  candidates: [],
  candidates_length: 0,
  candidates_loading: false,


  open_candidates: [],
  open_candidates_length: 0,
  open_candidates_loading: false,

  detail: {
    _id: "",
    first_name: "",
    last_name: "",
    birthday: "",
    address: "",
    email: "",
    phone: "",
    current_job: "",
    expect_salary: 0,
    interest: [],
    user: "",
    avatar: "",
    job_open: false,
    cv: "",
    photos: [],
    files: {
      name: "",
      url: "",
    },
    exp: [],
    study: [],
    project: [],
    note: [],
    upload_by: {},
    created_at: "",
    updated_at: "",
  },
  same_cv: [],
  loading: false,
};
export const CandidateReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case CandidateType.FETCH_CANDIDATE:
      return {
        ...state,
        loading: true,
        is_opened: false
      };
    case CandidateType.FETCH_CANDIDATE_SUCCEED:
      return {
        ...state,
        loading: false,
        candidate: action.payload.candidate,
        is_opened: action.payload.is_opened,
      };
    case CandidateType.FETCH_CANDIDATE_FAILED:
      return {
        ...state,
        loading: false,
        is_opened: false
      };

    case CandidateType.CREATE_CANDIDATE:
      return {
        ...state,
        loading: true,
      };
    case CandidateType.CREATE_CANDIDATE_SUCCEED:
      return {
        ...state,
        loading: false,
        detail: action.payload.detail,
      };
    case CandidateType.CREATE_CANDIDATE_FAILED:
      return {
        ...state,
        loading: false,
      };

    case CandidateType.UPDATE_CANDIDATE:
      return {
        ...state,
        loading: true,
      };
    case CandidateType.UPDATE_CANDIDATE_SUCCEED:
      return {
        ...state,
        loading: false,
        detail: action.payload.detail
      };
    case CandidateType.UPDATE_CANDIDATE_FAILED:
      return {
        ...state,
        loading: false
      };


    case CandidateType.FETCH_LIST_CANDIDATE:
      return {
        ...state,
        candidates_loading: true,
      }
    case CandidateType.FETCH_LIST_CANDIDATE_SUCCEED:
      return {
        ...state,
        candidates: action.payload.candidates,
        candidates_loading: false,
        candidates_length: action.payload.candidates_length
      }
    case CandidateType.FETCH_LIST_CANDIDATE_FAILED:
      return {
        ...state,
        candidates_loading: false,
      }


    case CandidateType.FETCH_LIST_OPEN_CANDIDATE:
      return {
        ...state,
        open_candidates: [],
        open_candidates_length: 0,
        open_candidates_loading: true,
      }
    case CandidateType.FETCH_LIST_OPEN_CANDIDATE_SUCCEED:
      return {
        ...state,
        open_candidates: action.payload.open_candidates,
        open_candidates_loading: false,
        open_candidates_length: action.payload.open_candidates_length
      }
    case CandidateType.FETCH_LIST_OPEN_CANDIDATE_FAILED:
      return {
        ...state,
        open_candidates: [],
        open_candidates_length: 0,
        open_candidates_loading: false,
      }
    default:
      return state;
  }
};
