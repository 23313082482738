enum ApplyListDetailType {
  FETCH_JOB_APPLY_DETAIL = "@@/ApplyListDetail/FETCH_JOB_APPLY_DETAIL",
  FETCH_JOB_APPLY_DETAIL_SUCCESS = "@@/ApplyListDetail/FETCH_JOB_APPLY_DETAIL_SUCCESS",
  FETCH_JOB_APPLY_DETAIL_FAILURE = "@@/ApplyListDetail/FETCH_JOB_APPLY_DETAIL_FAILURE",
  UPDATE_JOB_APPLY_DETAIL = "@@/ApplyListDetail/UPDATE_JOB_APPLY_DETAIL",
  UPDATE_JOB_APPLY_DETAIL_SUCCESS = "@@/ApplyListDetail/UPDATE_JOB_APPLY_DETAIL_SUCCESS",
  UPDATE_JOB_APPLY_DETAIL_FAILURE = "@@/ApplyListDetail/UPDATE_JOB_APPLY_DETAIL_FAILURE",
  SEND_EMAIL = "@@/ApplyListDetail/SEND_EMAIL",
  SEND_EMAIL_SUCCESS = "@@/ApplyListDetail/SEND_EMAIL_SUCCESS",
  SEND_EMAIL_FAILURE = "@@/ApplyListDetail/SEND_EMAIL_FAILURE"
}

interface ApplyListDetailState {
  loading: boolean;
  job_detail: any;
}

export default ApplyListDetailType;
export { ApplyListDetailState };
