enum JobLevelType {
  FETCH_JOB_LEVEL = "@@/JobLevel/FETCH_JOB_LEVEL",
  FETCH_JOB_LEVEL_SUCCEED = "@@/JobLevel/FETCH_JOB_LEVEL_SUCCEED",
  FETCH_JOB_LEVEL_FAILED = "@@/JobLevel/FETCH_JOB_LEVEL_FAILED",
}

interface IJobLevelState {
  job_levels: any[];
  job_levels_loading: boolean;
  job_levels_length: number;
}

export default JobLevelType;
export { IJobLevelState };
