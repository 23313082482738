import JobCategoryType, { IJobCategoryState } from "@/store/redux/types/JobCategoryType";

const defaultState: IJobCategoryState = {
  job_categorys: [],
  job_categorys_length: 0,
  job_categorys_loading: false,
};

export const JobCategoryReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case JobCategoryType.FETCH_JOB_CATEGORY:
      return {
        ...state,
        job_categorys_loading: true,
      };
    case JobCategoryType.FETCH_JOB_CATEGORY_SUCCEED:
      return {
        ...state,
        job_categorys: action.payload.job_categorys,
        job_categorys_length: action.payload.job_categorys_length,
        job_categorys_loading: false,
      };
    case JobCategoryType.FETCH_JOB_CATEGORY_FAILED:
      return {
        ...state,
        job_categorys_loading: false,
      };
    default:
      return state;
  }
};
