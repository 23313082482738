import React, { ComponentType } from "react";
import Page from "@/components/Page/Page";
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface IProps {

}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    card: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),
    }
}));

const LoginPage: ComponentType<IProps> = (props: IProps) => {

    const classes = useStyles();

    return (
        <Page title={""} heading={""} className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={3} sm={3}>

                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card className={classes.card}>
                        <CardHeader title={"Đăng nhập hệ thống nhà tuyển dụng"} />
                        <CardContent>
                            <Button href={`${process.env.REACT_APP_LOGIN_GOOGLE}`} fullWidth className={classes.button} variant="outlined" color="primary">Đăng nhập bằng Google</Button>
                            {/* <Button href={`${process.env.REACT_APP_LOGIN_FACEBOOK}`} fullWidth className={classes.button} variant="outlined" color="primary">Đăng nhập bằng Facebook</Button> */}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={3} sm={6}>

                </Grid>
            </Grid>

        </Page>
    )
};

export default LoginPage;
