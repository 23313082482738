enum AddressType {
  FETCH_CITY = "@@/Address/FETCH_CITY",
  FETCH_CITY_SUCCEED = "@@/Address/FETCH_CITY_SUCCEED",
  FETCH_CITY_FAILED = "@@/Address/FETCH_CITY_FAILED",

  FETCH_DISTRICT = "@@/Address/FETCH_DISTRICT",
  FETCH_DISTRICT_SUCCEED = "@@/Address/FETCH_DISTRICT_SUCCEED",
  FETCH_DISTRICT_FAILED = "@@/Address/FETCH_DISTRICT_FAILED",

  FETCH_WARD = "@@/Address/FETCH_WARD",
  FETCH_WARD_SUCCEED = "@@/Address/FETCH_WARD_SUCCEED",
  FETCH_WARD_FAILED = "@@/Address/FETCH_WARD_FAILED",
}

interface IAddressState {
  citys: any[];
  citys_loading: boolean;
  citys_length: number;
  districts: any[];
  districts_loading: boolean;
  districts_length: number;
  wards: any[];
  wards_loading: boolean;
  wards_length: number;
}

export default AddressType;
export { IAddressState };
