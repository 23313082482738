import axiosBase, { AxiosInstance } from "axios";

const windowExist = typeof window !== "undefined";

export type AxiosRequestType = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

// Create environment when do SSR
if (!windowExist) {
  require("dotenv").config();
}

const headers = {
  Accept: "application/json",
};

const instance: AxiosInstance = axiosBase.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers,
});

export const axios = instance;
