import CandidateType from "@/store/redux/types/CandidateType";
import { all, delay, fork, put, takeLatest } from "redux-saga/effects";
import { apolloClient } from "@/utils/apolloClient";
import {
  createCandidateFailed,
  createCandidateSucceed,
  fetchCandidateFailed,
  fetchCandidateSucceed, fetchListOpenCandidateFailed,
  fetchListOpenCandidateSucceed,
  openCandidateFailed,
  openCandidateSucceed,
  updateCandidateFailed,
  updateCandidateSucceed
} from "@/store/redux/actions/CandidateAction";
import { CANDIDATE_DETAIL, PAGINATE_CANDIDATE } from "@/graphql/candidate/query";
import { CREATE_CANDIDATE, UPDATE_CANDIDATE } from "@/graphql/candidate/mutation";
import { closeModal } from "@/store/redux/actions/GlobalModalAction";
import { enqueueSnackbar } from "@/store/redux/actions/GlobalToastAction";
import { graphqlFilter } from "@/helpers/string";
import { fetchListCandidateFailed, fetchListCandidateSucceed } from "../../actions/CandidateAction";
import { axios } from "@/utils/axiosInstance";

function* fetchListCandidate(action: any) {
  try {
    let actionPayload = action.payload;
    let page = actionPayload.page
    let keyword = actionPayload.keyword || "";
    let address = actionPayload.address || "";
    let r = yield axios.get(`${process.env.REACT_APP_DATA_API_URL}/candidate/paginate?page=${page}&keyword=${keyword}&address=${address}`);
    let responsePayload = {
      candidates: r.data.candidates,
      candidates_length: r.data.length,
    }
    yield put(fetchListCandidateSucceed(responsePayload));
  } catch (e) {
    yield put(fetchListCandidateFailed());
  }
}


function* fetchListOpenCandidate(action: any) {
  try {
    let actionPayload = action.payload;
    let page = actionPayload.page
    let keyword = actionPayload.keyword || "";
    let address = actionPayload.address || "";
    let r = yield axios.get(`${process.env.REACT_APP_DATA_API_URL}/candidate/open/paginate?page=${page}&keyword=${keyword}&address=${address}`);
    let responsePayload = {
      open_candidates: r.data.open_candidates,
      open_candidates_length: r.data.length,
    }
    yield put(fetchListOpenCandidateSucceed(responsePayload));
  } catch (e) {
    yield put(fetchListOpenCandidateFailed());
  }
}

function* fetchCandidate(action: any) {
  try {
    let actionPayload = action.payload;
    let id = actionPayload.id;
    let token = actionPayload?.token || "";

    let r = yield axios.get(`${process.env.REACT_APP_DATA_API_URL}/candidate/${id}?token=${token}`);
    let responsePayload = {
      candidate: r.data.candidate,
      is_opened: r.data.is_opened,
    }

    yield put(fetchCandidateSucceed(responsePayload));

  } catch (e) {
    yield put(fetchCandidateFailed())
  }
}

function* openCandidate(action: any) {
  try {
    let actionPayload = action.payload;
    let id = actionPayload.id;

    let r = yield axios.get(`${process.env.REACT_APP_DATA_API_URL}/candidate/${id}/access`);
    if (r.data.status) {
      const toast = {
        message: "Mở CV thành công",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
      };
      yield put(closeModal());
      yield put(enqueueSnackbar(toast));
      yield openCandidateSucceed(r.candidate)
      yield window.location.reload();
    } else {
      const toast = {
        message: r.data.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      };
      yield put(closeModal());
      yield put(enqueueSnackbar(toast));
      yield put(openCandidateFailed())
    }
  } catch (e) {
    const toast = {
      message: "Lỗi máy chủ",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(openCandidateFailed())
  }
}

function* createCandidateSaga(action: any) {
  try {
    yield delay(300);
    let r = yield apolloClient.mutate({ mutation: CREATE_CANDIDATE, variables: action.payload.variables });
    const toast = {
      message: "Cập nhật thành công",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(createCandidateSucceed({
      detail: r.data.candidateCreate,
    }));
    yield (window.location.href = `/cv/list`);
  } catch (e) {
    const toast = {
      message: "Lỗi cập nhât máy chủ",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(createCandidateFailed())
  }
}

function* updateCandidateSaga(action: any) {
  try {
    yield delay(300);
    let r = yield apolloClient.mutate({ mutation: UPDATE_CANDIDATE, variables: action.payload.variables });
    const toast = {
      message: "Cập nhật thành công",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    // yield put(updateCandidateSucceed({
    //   detail: r.data.candidateUpdate,
    // }))
    yield window.location.reload();
  } catch (e) {
    const toast = {
      message: "Lỗi cập nhât máy chủ",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(updateCandidateFailed())
  }
}

function* watchFetchCandidateSaga() {
  yield takeLatest(CandidateType.FETCH_LIST_OPEN_CANDIDATE, fetchListOpenCandidate);
  yield takeLatest(CandidateType.FETCH_LIST_CANDIDATE, fetchListCandidate);
  yield takeLatest(CandidateType.OPEN_CANDIDATE, openCandidate);
  yield takeLatest(CandidateType.FETCH_CANDIDATE, fetchCandidate);
  yield takeLatest(CandidateType.CREATE_CANDIDATE, createCandidateSaga);
  yield takeLatest(CandidateType.UPDATE_CANDIDATE, updateCandidateSaga);
}

function* candidateSaga() {
  yield all([
    fork(watchFetchCandidateSaga),
  ])
}

export default candidateSaga;
