enum ListCompanyType {
  FETCH_LIST_COMPANY = "@@ListCompany/FETCH_LIST_COMPANY",
  FETCH_LIST_COMPANY_SUCCEED = "@@ListCompany/FETCH_LIST_COMPANY_SUCCEED",
  FETCH_LIST_COMPANY_FAILED = "@@ListCompany/FETCH_LIST_COMPANY_FAILED",
}

interface IListCompanyState {
  loading: boolean,
  company: any[];
}

export default ListCompanyType;
export { IListCompanyState };
