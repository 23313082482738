import JobTypeType from "@/store/redux/types/JobTypeType";

export const fetchJobType = (payload: any) => ({
  type: JobTypeType.FETCH_JOB_TYPE,
  payload
});

export const fetchJobTypeSucceed = (payload: any) => ({
  type: JobTypeType.FETCH_JOB_TYPE_SUCCEED,
  payload
});

export const fetchJobTypeFailed = () => ({
  type: JobTypeType.FETCH_JOB_TYPE_FAILED,
});
