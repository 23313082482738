import WarehouseType, { IWarehouseState } from "@/store/redux/types/WarehouseType";

const defaultState: IWarehouseState = {
    warehouses: [],
    warehouses_loading: false,
    warehouses_length: 0,

    warehouse_delete_loading: false,
    warehouse_create_loading: false,
    warehouse_update_loading: false,

    warehouse: null,
    warehouse_loading: false,


    cvs: [],
    cvs_loading: false,
    cvs_length: 0,

    cv_delete_loading: false,
    cv_create_loading: false,
    cv_update_loading: false,

    cv: null,
    cv_loading: false,
};
export const WarehouseReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case WarehouseType.FETCH_LIST_WAREHOUSE:
            return {
                ...state,
                warehouses: [],
                warehouses_length: 0,
                warehouses_loading: true,
            }
        case WarehouseType.FETCH_LIST_WAREHOUSE_SUCCEED:
            return {
                ...state,
                warehouses_loading: false,
                warehouses: action.payload.warehouses,
                warehouses_length: action.payload.warehouses_length,
            }
        case WarehouseType.FETCH_LIST_WAREHOUSE_FAILED:
            return {
                ...state,
                warehouses: [],
                warehouses_length: 0,
                warehouses_loading: false,
            }
        case WarehouseType.CREATE_WAREHOUSE:
            return {
                ...state,
                warehouse_create_loading: true,
            };
        case WarehouseType.CREATE_WAREHOUSE_SUCCEED:
            return {
                ...state,
                warehouse_create_loading: false,
            };
        case WarehouseType.CREATE_WAREHOUSE_FAILED:
            return {
                ...state,
                warehouse_create_loading: false,
            };

        case WarehouseType.DELETE_WAREHOUSE:
            return {
                ...state,
                warehouse_delete_loading: true,
            };
        case WarehouseType.DELETE_WAREHOUSE_SUCCEED:
            return {
                ...state,
                warehouse_delete_loading: false,
            };
        case WarehouseType.DELETE_WAREHOUSE_FAILED:
            return {
                ...state,
                warehouse_delete_loading: false,
            };

        case WarehouseType.FETCH_WAREHOUSE:
            return {
                ...state,
                warehouse: null,
                warehouse_loading: true,
            };
        case WarehouseType.FETCH_WAREHOUSE_SUCCEED:
            return {
                ...state,
                warehouse_loading: false,
                warehouse: action.payload.warehouse,
            };
        case WarehouseType.FETCH_WAREHOUSE_FAILED:
            return {
                ...state,
                warehouse: null,
                warehouse_loading: false,
            };
        case WarehouseType.UPDATE_WAREHOUSE:
            return {
                ...state,
                warehouse_update_loading: true,
            };
        case WarehouseType.UPDATE_WAREHOUSE_SUCCEED:
            return {
                ...state,
                warehouse_update_loading: false,
            };
        case WarehouseType.UPDATE_WAREHOUSE_FAILED:
            return {
                ...state,
                warehouse_update_loading: false,
            };



        case WarehouseType.FETCH_LIST_CV:
            return {
                ...state,
                cvs: [],
                cvs_length: 0,
                cvs_loading: true,
            }
        case WarehouseType.FETCH_LIST_CV_SUCCEED:
            return {
                ...state,
                cvs_loading: false,
                cvs: action.payload.cvs,
                cvs_length: action.payload.cvs_length,
            }
        case WarehouseType.FETCH_LIST_CV_FAILED:
            return {
                ...state,
                cvs: [],
                cvs_length: 0,
                cvs_loading: false,
            }
        case WarehouseType.CREATE_CV:
            return {
                ...state,
                cv_create_loading: true,
            };
        case WarehouseType.CREATE_CV_SUCCEED:
            return {
                ...state,
                cv_create_loading: false,
            };
        case WarehouseType.CREATE_CV_FAILED:
            return {
                ...state,
                cv_create_loading: false,
            };

        case WarehouseType.DELETE_CV:
            return {
                ...state,
                cv_delete_loading: true,
            };
        case WarehouseType.DELETE_CV_SUCCEED:
            return {
                ...state,
                cv_delete_loading: false,
            };
        case WarehouseType.DELETE_CV_FAILED:
            return {
                ...state,
                cv_delete_loading: false,
            };

        case WarehouseType.FETCH_CV:
            return {
                ...state,
                cv: null,
                cv_loading: true,
            };
        case WarehouseType.FETCH_CV_SUCCEED:
            return {
                ...state,
                cv_loading: false,
                cv: action.payload.cv,
            };
        case WarehouseType.FETCH_CV_FAILED:
            return {
                ...state,
                cv: null,
                cv_loading: false,
            };
        case WarehouseType.UPDATE_CV:
            return {
                ...state,
                cv_update_loading: true,
            };
        case WarehouseType.UPDATE_CV_SUCCEED:
            return {
                ...state,
                cv_update_loading: false,
            };
        case WarehouseType.UPDATE_CV_FAILED:
            return {
                ...state,
                cv_update_loading: false,
            };
        default:
            return state;
    }
};
