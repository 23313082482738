import { UPDATE_STATUS_JOB_APPLY } from "@/graphql/job_apply/mutation";
import {
  fetchJobApplyDetailFailure,



  sendEmailFailure, sendEmailSuccess, updateJobApplySuccess
} from "@/store/redux/actions/ApplyListDetailAction";
import ApplyListDetailType from "@/store/redux/types/ApplyListDetailType";
import { apolloClient } from "@/utils/apolloClient";
import axios from "axios";
import { all, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";



function* fetchJobApplyListDetailSaga(action: any) {
  try {
    // let r = yield apolloClient.query({ query: SINGLE_JOB_APPLY, variables: { _id: action.payload } });
    // yield put(fetchJobApplyDetailSuccess(r.data.jobApply));
  } catch (e) {
    yield put(fetchJobApplyDetailFailure({ message: e.message }));
  }
}

function* updateJobApplyListDetailSaga(action: any) {
  try {
    let r = yield apolloClient.mutate({ mutation: UPDATE_STATUS_JOB_APPLY, variables: { input: action.payload } });
    let { job_detail } = yield select(function (state: any) {
      return state.applyDetail;
    });
    let stateUpdate = Object.assign({ ...job_detail, status: action.payload.status });
    yield put(updateJobApplySuccess(stateUpdate));
  } catch (e) { }
}
function* sendEmailNoreplySaga(action: any) {
  try {
    let r = yield axios.post(`${process.env.REACT_APP_API_URL}/noreply/send`, { data: action.payload });
    if (r.status === 200) {
      yield put(sendEmailSuccess());
    }
  } catch (e) {
    yield put(sendEmailFailure());
  }
}

function* watchApplyListDetailSaga() {
  yield takeEvery(ApplyListDetailType.FETCH_JOB_APPLY_DETAIL, fetchJobApplyListDetailSaga);
  yield takeEvery(ApplyListDetailType.UPDATE_JOB_APPLY_DETAIL, updateJobApplyListDetailSaga);
  yield takeLatest(ApplyListDetailType.SEND_EMAIL, sendEmailNoreplySaga);
}

function* ApplyListDetailSaga() {
  yield all([fork(watchApplyListDetailSaga)]);
}

export default ApplyListDetailSaga;
