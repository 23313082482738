import UserType from "@/store/redux/types/UserType";

export interface UserAction {
  readonly type: UserType;
  payload?: any;
}

export const fetchUser = () => ({
  type: UserType.FETCH_USER,
});

export const fetchUserSucceed = (payload: any) => ({
  type: UserType.FETCH_USER_SUCCEED,
  payload: payload,
});

export const fetchUserFailed = () => ({
  type: UserType.FETCH_USER_FAILED
})
