import palette from "@/theme/palette";

export default {
  root: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: '#919EAB',
      borderWidth: 1.4,
    },
    "&:active .MuiOutlinedInput-notchedOutline": {
      borderColor: '#919EAB',
      borderWidth: 1.4,
    },
    "&:visited .MuiOutlinedInput-notchedOutline": {
      borderColor: '#919EAB',
      borderWidth: 1.4,
    },
    "&:focus-within .MuiOutlinedInput-notchedOutline": {
      borderColor: '#919EAB',
      borderWidth: 1.4,
    },
    "&:focus .MuiOutlinedInput-notchedOutline": {
      borderColor: '#919EAB',
      borderWidth: 1.4,
    },
  },
  notchedOutline: {
    borderColor: '#DFE3E8',
    borderWidth: 1.4,
  },
};
