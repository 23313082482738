enum CompanyType {
  FETCH_COMPANY = "@@/Company/FETCH_COMPANY",
  FETCH_COMPANY_SUCCEED = "@@/Company/FETCH_COMPANY_SUCCEED",
  FETCH_COMPANY_FAILED = "@@/Company/FETCH_COMPANY_FAILED",

  UPDATE_COMPANY = "@@/Company/UPDATE_COMPANY",
  UPDATE_COMPANY_SUCCEED = "@@/Company/UPDATE_COMPANY_SUCCEED",
  UPDATE_COMPANY_FAILED = "@@/Company/UPDATE_COMPANY_FAILED",

  CREATE_COMPANY = "@@/Company/CREATE_COMPANY",
  CREATE_COMPANY_SUCCEED = "@@/Company/CREATE_COMPANY_SUCCEED",
  CREATE_COMPANY_FAILED = "@@/Company/CREATE_COMPANY_FAILED",

  FETCH_LIST_COMPANY = "@@/Company/FETCH_LIST_COMPANY",
  FETCH_LIST_COMPANY_SUCCEED = "@@/Company/FETCH_LIST_COMPANY_SUCCEED",
  FETCH_LIST_COMPANY_FAILED = "@@/Company/FETCH_LIST_COMPANY_FAILED",
}

interface ICompanyDetail {
  _id: string,
  name: string,
  business_code: string,
  slug: string,
  verify_status: boolean,
  logo: string,
  cover: string,
  website: string,
  phone: string,
  follow: string,
  facebook: string,
  youtube: string,
  description: string,
  slogan: string,
  album: {
    name: string,
    images: {
      src: string,
      description: string,
    }[]
  }[],
  benefit: {
    id: {
      _id: string,
      title: string,
    },
    content: string,
  }[],
  story: {
    title: string,
    content: string,
    media_type: string,
    media_link: string,
  }[],
  job_category: {
    _id: string,
    title: string,
    slug: string,
  },
  created_by: {
    first_name: string,
    last_name: string,
    avatar: string,
  },
  office: {
    city: {
      _id: string,
      name: string,
    },
    district: {
      _id: string,
      name: string,
    },
    ward: {
      _id: string,
      name: string,
    },
    address: string,
    lat: string,
    lng: string,
  }[],
  people: {
    name: string,
    content: string,
    position: string,
    avatar: string,
  }[],
}


interface ICompanyState {
  companys: any[];
  companys_loading: boolean;
  companys_length: number;

  company_loading: boolean;
  company: any;

  company_create_loading: boolean;
  company_update_loading: boolean;

  detail: ICompanyDetail;
  loading: boolean;
}

export default CompanyType;
export { ICompanyState };
