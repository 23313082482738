import CreditType, {ICreditState} from "@/store/redux/types/CreditType";

const defaultState: ICreditState = {
  credit: 0,
  credit_loading: false
}

export const CreditReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case CreditType.FETCH_CREDIT:
      return {
        ...state,
        loading: true
      }
    case CreditType.FETCH_CREDIT_FAILED:
      return {
        ...state,
        loading: false
      }

    case CreditType.FETCH_CREDIT_SUCCEED:
      return {
        ...state,
        credit: action.payload.credit,
        loading: false
      }
    default:
      return state;
  }
}
