import JobCategoryType from "@/store/redux/types/JobCategoryType";
import { takeLatest, put, fork, all, delay } from "redux-saga/effects";
import { apolloClient } from "@/utils/apolloClient";
import { fetchJobCategoryFailed, fetchJobCategorySucceed } from "@/store/redux/actions/JobCategoryAction";
import { getListJobCategory } from "@/graphql/job_category/query";
import { axios } from "@/utils/axiosInstance";

function* fetchJobCategory(action: any) {
  try {
    let actionPayload = action.payload;
    console.log(actionPayload);
    let r = yield axios.post("/graphql", {
      query: getListJobCategory(actionPayload.page, actionPayload.limit, actionPayload.filter)
    });
    let responsePayload = {
      job_categorys: r.data.data.jobCategorys.edges,
      job_category_length: r.data.data.jobCategorys.pageInfo.length,
    }
    yield put(fetchJobCategorySucceed(responsePayload))
  } catch (e) {
    yield put(fetchJobCategoryFailed())
  }
}

function* watchFetchJobCategory() {
  yield takeLatest(JobCategoryType.FETCH_JOB_CATEGORY, fetchJobCategory)
}

function* jobCategorySaga() {
  yield all([
    fork(watchFetchJobCategory),
  ])
}

export default jobCategorySaga;
