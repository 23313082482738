import gql from "graphql-tag";

export const CREATE_CANDIDATE = gql`
    mutation createCandidate($input: CandidateInput!) {
        candidateCreate(input: $input) {
            _id
            first_name
            last_name
            interest
            user {
                _id
            }
            avatar
            job_open
            cv
            photos
            files {
                name
                url
            }
            created_at
            updated_at
        }
    }
`;

export const UPDATE_CANDIDATE = gql`
    mutation updateCandidate($input: CandidateInput!) {
        candidateUpdate(input: $input) {
            _id
            first_name
            last_name
            interest
            user {
                _id
            }
            avatar
            job_open
            cv
            photos
            files {
                name
                url
            }
            created_at
            updated_at
        }
    }
`;
