export const getListCvEmployer = (page: number, limit: number, filter: string) => `
    query {
        cvEmployers(page: ${page}, limit: ${limit}, filter: "${filter}") {
            edges {
                node {
                    _id
                    origin_url
                    title
                    name
                    email
                    phone
                    created_at
                    updated_at
                }
            }
            pageInfo {
                length
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

export const getCvEmployerById = (_id: string) => `
    query {
        cvEmployer(_id: "${_id}") {
            _id
            origin_url
            title
            name
            email
            phone
            created_at
            updated_at
        }
    }
`;
