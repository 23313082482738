enum JobPostType {
  FETCH_JOB_POST = "@@/JobPost/FETCH_JOB_POST",
  FETCH_JOB_POST_SUCCEED = "@@/JobPost/FETCH_JOB_POST_SUCCEED",
  FETCH_JOB_POST_FAILED = "@@/JobPost/FETCH_JOB_POST_FAILED",

  UPDATE_JOB_POST = "@@/JobPost/UPDATE_JOB_POST",
  UPDATE_JOB_POST_SUCCEED = "@@/JobPost/UPDATE_JOB_POST_SUCCEED",
  UPDATE_JOB_POST_FAILED = "@@/JobPost/UPDATE_JOB_POST_FAILED",

  CREATE_JOB_POST = "@@/JobPost/CREATE_JOB_POST",
  CREATE_JOB_POST_SUCCEED = "@@/JobPost/CREATE_JOB_POST_SUCCEED",
  CREATE_JOB_POST_FAILED = "@@/JobPost/CREATE_JOB_POST_FAILED",

  DELETE_JOB_POST = "@@/JobPost/DELETE_JOB_POST",
  DELETE_JOB_POST_SUCCEED = "@@/JobPost/DELETE_JOB_POST_SUCCEED",
  DELETE_JOB_POST_FAILED = "@@/JobPost/DELETE_JOB_POST_FAILED",

  FETCH_LIST_JOB_POST = "@@/JobPost/FETCH_LIST_JOB_POST",
  FETCH_LIST_JOB_POST_SUCCEED = "@@/JobPost/FETCH_LIST_JOB_POST_SUCCEED",
  FETCH_LIST_JOB_POST_FAILED = "@@/JobPost/FETCH_LIST_JOB_POST_FAILED",

}

interface IJobPostState {
  job_posts: any[];
  job_posts_length: number;
  job_posts_loading: boolean;

  job_post_delete_loading: boolean;

  job_post_create_loading: boolean;

  job_post_update_loading: boolean;

  job_post: any;
  job_post_loading: boolean;

  detail: any;
  loading: boolean;
}

export default JobPostType;
export { IJobPostState };
