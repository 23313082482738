import JobTypeType, { IJobTypeState } from "@/store/redux/types/JobTypeType";

const defaultState: IJobTypeState = {
  job_types: [],
  job_types_loading: false,
  job_types_length: 0,
};

export const JobTypeReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case JobTypeType.FETCH_JOB_TYPE:
      return {
        ...state,
        job_types_loading: true,
      };
    case JobTypeType.FETCH_JOB_TYPE_SUCCEED:
      return {
        ...state,
        job_types: action.payload.job_types,
        job_types_length: action.payload.job_types_length,
        job_types_loading: false
      };
    case JobTypeType.FETCH_JOB_TYPE_FAILED:
      return {
        ...state,
        job_types_loading: false
      };
    default:
      return state;
  }
};
