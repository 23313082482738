import JobTitleType from "@/store/redux/types/JobTitleType";

export const fetchJobTitle = (payload: any) => ({
  type: JobTitleType.FETCH_JOB_TITLE,
  payload
});

export const fetchJobTitleSucceed = (payload: any) => ({
  type: JobTitleType.FETCH_JOB_TITLE_SUCCEED,
  payload
});

export const fetchJobTitleFailed = () => ({
  type: JobTitleType.FETCH_JOB_TITLE_FAILED,
});
