import JobPostType from "@/store/redux/types/JobPostType";

export const createJobPost = (payload: any) => ({
  type: JobPostType.CREATE_JOB_POST,
  payload,
});

export const createJobPostSucceed = () => ({
  type: JobPostType.CREATE_JOB_POST_SUCCEED,
});

export const createJobPostFailed = () => ({
  type: JobPostType.CREATE_JOB_POST_FAILED
});

export const deleteJobPost = (payload: any) => ({
  type: JobPostType.DELETE_JOB_POST,
  payload,
});

export const deleteJobPostSucceed = () => ({
  type: JobPostType.DELETE_JOB_POST_SUCCEED,
});

export const deleteJobPostFailed = () => ({
  type: JobPostType.DELETE_JOB_POST_FAILED
});

export const fetchJobPost = (payload: any) => ({
  type: JobPostType.FETCH_JOB_POST,
  payload,
});

export const fetchJobPostSucceed = (payload: any) => ({
  type: JobPostType.FETCH_JOB_POST_SUCCEED,
  payload,
});

export const fetchJobPostFailed = () => ({
  type: JobPostType.FETCH_JOB_POST_FAILED
});


export const updateJobPost = (payload: any) => ({
  type: JobPostType.UPDATE_JOB_POST,
  payload,
});

export const updateJobPostSucceed = () => ({
  type: JobPostType.UPDATE_JOB_POST_SUCCEED
});
export const updateJobPostFailed = () => ({
  type: JobPostType.UPDATE_JOB_POST_FAILED,
});


export const fetchListJobPost = (payload: any) => ({
  type: JobPostType.FETCH_LIST_JOB_POST,
  payload,
});

export const fetchListJobPostSucceed = (payload: any) => ({
  type: JobPostType.FETCH_LIST_JOB_POST_SUCCEED,
  payload,
});

export const fetchListJobPostFailed = () => ({
  type: JobPostType.FETCH_LIST_JOB_POST_FAILED
});

