import NotificationType from "@/store/redux/types/NotificationType";
import { all, delay, fork, put, takeLatest } from "redux-saga/effects";
import { apolloClient } from "@/utils/apolloClient";
import {
  fetchNotificationFailed,
  fetchNotificationSucceed,
  fetchUnreadNotificationLengthFailed,
  fetchUnreadNotificationLengthSucceed,
  updateReadAllNotificationFailed,
  updateReadAllNotificationSucceed, updateReadNotificationFailed, updateReadNotificationSucceed
} from "@/store/redux/actions/NotificationAction";
import { NOTIFICATION_LENGTH, PAGINATE_NOTIFICATION } from "@/graphql/notification/query";
import { UPDATE_READ_ALL_NOTIFICATION, UPDATE_READ_NOTIFICATION } from "@/graphql/notification/mutation";

function* fetchNotificationSaga(action: any) {
  try {
    yield delay(300);
    let r = yield apolloClient.query({ query: PAGINATE_NOTIFICATION, variables: action.payload.variables });
    yield put(fetchNotificationSucceed({
      notification: r.data.notifications.edges,
      length: r.data.notifications.pageInfo.length
    }))
  } catch (e) {
    yield put(fetchNotificationFailed())
  }
}

function* fetchUnreadNotificationLengthSaga(action: any) {
  try {
    yield delay(300);

    let r = yield apolloClient.query({ query: NOTIFICATION_LENGTH, variables: action.payload.variables });
    yield put(fetchUnreadNotificationLengthSucceed({ unread_length: r.data.notifications.pageInfo.length }));
  } catch (e) {
    yield put(fetchUnreadNotificationLengthFailed())
  }
}

function* updateReadNotificationSaga(action: any) {
  try {
    yield delay(300);
    let r = yield apolloClient.mutate({ mutation: UPDATE_READ_NOTIFICATION, variables: { input: action.payload } });
    yield put(updateReadNotificationSucceed());
    yield window.location.reload();
  } catch (e) {
    yield put(updateReadNotificationFailed());
  }
}

function* updateReadAllNotificationSaga() {
  try {
    yield delay(300);
    let r = yield apolloClient.query({ query: UPDATE_READ_ALL_NOTIFICATION, variables: { input: {} } });
    yield put(updateReadAllNotificationSucceed());
  } catch (e) {
    yield put(updateReadAllNotificationFailed())
  }
}

function* watchFetchNotificationSaga() {
  yield takeLatest(NotificationType.FETCH_NOTIFICATION, fetchNotificationSaga);
  yield takeLatest(NotificationType.FETCH_UNREAD_NOTIFICATION_LENGTH, fetchUnreadNotificationLengthSaga)
  yield takeLatest(NotificationType.UPDATE_READ_NOTIFICATION, updateReadNotificationSaga)
  yield takeLatest(NotificationType.UPDATE_READ_ALL_NOTIFICATION, updateReadAllNotificationSaga)
}

function* notificationSaga() {
  yield all([fork(watchFetchNotificationSaga)]);
}

export default notificationSaga;
