enum WarehouseType {
    FETCH_WAREHOUSE = "@@/Warehouse/FETCH_WAREHOUSE",
    FETCH_WAREHOUSE_SUCCEED = "@@/Warehouse/FETCH_WAREHOUSE_SUCCEED",
    FETCH_WAREHOUSE_FAILED = "@@/Warehouse/FETCH_WAREHOUSE_FAILED",

    UPDATE_WAREHOUSE = "@@/Warehouse/UPDATE_WAREHOUSE",
    UPDATE_WAREHOUSE_SUCCEED = "@@/Warehouse/UPDATE_WAREHOUSE_SUCCEED",
    UPDATE_WAREHOUSE_FAILED = "@@/Warehouse/UPDATE_WAREHOUSE_FAILED",

    CREATE_WAREHOUSE = "@@/Warehouse/CREATE_WAREHOUSE",
    CREATE_WAREHOUSE_SUCCEED = "@@/Warehouse/CREATE_WAREHOUSE_SUCCEED",
    CREATE_WAREHOUSE_FAILED = "@@/Warehouse/CREATE_WAREHOUSE_FAILED",

    DELETE_WAREHOUSE = "@@/Warehouse/DELETE_WAREHOUSE",
    DELETE_WAREHOUSE_SUCCEED = "@@/Warehouse/DELETE_WAREHOUSE_SUCCEED",
    DELETE_WAREHOUSE_FAILED = "@@/Warehouse/DELETE_WAREHOUSE_FAILED",

    FETCH_LIST_WAREHOUSE = "@@/Warehouse/FETCH_LIST_WAREHOUSE",
    FETCH_LIST_WAREHOUSE_SUCCEED = "@@/Warehouse/FETCH_LIST_WAREHOUSE_SUCCEED",
    FETCH_LIST_WAREHOUSE_FAILED = "@@/Warehouse/FETCH_LIST_WAREHOUSE_FAILED",


    FETCH_CV = "@@/Warehouse/FETCH_CV",
    FETCH_CV_SUCCEED = "@@/Warehouse/FETCH_CV_SUCCEED",
    FETCH_CV_FAILED = "@@/Warehouse/FETCH_CV_FAILED",

    UPDATE_CV = "@@/Warehouse/UPDATE_CV",
    UPDATE_CV_SUCCEED = "@@/Warehouse/UPDATE_CV_SUCCEED",
    UPDATE_CV_FAILED = "@@/Warehouse/UPDATE_CV_FAILED",

    CREATE_CV = "@@/Warehouse/CREATE_CV",
    CREATE_CV_SUCCEED = "@@/Warehouse/CREATE_CV_SUCCEED",
    CREATE_CV_FAILED = "@@/Warehouse/CREATE_CV_FAILED",

    DELETE_CV = "@@/Warehouse/DELETE_CV",
    DELETE_CV_SUCCEED = "@@/Warehouse/DELETE_CV_SUCCEED",
    DELETE_CV_FAILED = "@@/Warehouse/DELETE_CV_FAILED",

    FETCH_LIST_CV = "@@/Warehouse/FETCH_LIST_CV",
    FETCH_LIST_CV_SUCCEED = "@@/Warehouse/FETCH_LIST_CV_SUCCEED",
    FETCH_LIST_CV_FAILED = "@@/Warehouse/FETCH_LIST_CV_FAILED",

}

interface IWarehouseState {
    warehouses: any[];
    warehouses_length: number;
    warehouses_loading: boolean;

    warehouse_delete_loading: boolean;

    warehouse_create_loading: boolean;

    warehouse_update_loading: boolean;

    warehouse: any;
    warehouse_loading: boolean;


    cvs: any[];
    cvs_length: number;
    cvs_loading: boolean;

    cv_delete_loading: boolean;

    cv_create_loading: boolean;

    cv_update_loading: boolean;

    cv: any;
    cv_loading: boolean;
}

export default WarehouseType;
export { IWarehouseState };
