enum JobCategoryType {
  FETCH_JOB_CATEGORY = "@@/JobCategory/FETCH_JOB_CATEGORY",
  FETCH_JOB_CATEGORY_SUCCEED = "@@/JobCategory/FETCH_JOB_CATEGORY_SUCCEED",
  FETCH_JOB_CATEGORY_FAILED = "@@/JobCategory/FETCH_JOB_CATEGORY_FAILED",
}

interface IJobCategoryState {
  job_categorys: any[];
  job_categorys_length: number;
  job_categorys_loading: boolean;
}

export default JobCategoryType;
export { IJobCategoryState };
