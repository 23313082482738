export const getListJobLevel = (page: number, limit: number, filter: string) => `
    query {
        jobLevels(page: ${page}, limit: ${limit}, filter: "${filter}") {
            edges {
                node {
                    _id
                    title
                }
            }
            pageInfo {
                length
            }
        }
    }
`;
