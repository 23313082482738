import gql from "graphql-tag";

export const getListJobType = (page: number, limit: number, filter: string) => `
  query {
    jobTypes(page: ${page}, limit: ${limit}, filter: "${filter}") {
      edges {
        node {
          _id
          title
        }
      }
      pageInfo {
        length
      }
    }
  }
`;

export const JOB_TYPE = gql`
  query jobType($id: String!) {
    jobType(_id: $id) {
      _id
      title
    }
  }
`;
